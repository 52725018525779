import { WORD_ENTER_LOGIN_PASSWORD, WORD_ENTER_USERNAME, WORD_FIELD_CANT_BLANK_WARNING, WORD_SHOULD_ATTACH_PDF_FILE } from "../constants/translation"

function RegexMatch(text, regex) {
    return new Promise((resolve, reject) => {
        if (regex.test(text.trim())) {
            resolve()
        } else {
            reject('Invalid input')
        }
    })
}

function lengthCheck(text, maxLength) {
    return new Promise((resolve, reject) => {
        if (text.trim().length <= maxLength) {
            resolve()
        } else {
            reject(`You have exceeded the maximum limit of ${maxLength} characters`)
        }
    })
}

function blankCheck(input, errorMessage) {
    if (!input) input = ''
    return new Promise((resolve, reject) => {
        if (input?.trim().length === 0) reject(errorMessage)
        else resolve()
    })
}

// function ArrayBlankCheck(array, errorMessage) {
//     array.map(())
// }

function blankFileCheck(input, errorMessage) {
    return new Promise((resolve, reject) => {
        if (input) resolve()
        else reject(errorMessage)
    })
}

// export const onOTPrequestValidation = async (inputs, setErrors) => {
//     let status = false
//     return Promise.all([
//         await lengthCheck(inputs.first_name || '', 40).then(() => status = true).catch((err) => (status = false, setErrors((s) => ({ ...s, first_name: err })))),
//         await lengthCheck(inputs.last_name || '', 40).then(() => status = true).catch((err) => (status = false, setErrors((s) => ({ ...s, last_name: err })))),

//         await RegexMatch(inputs.first_name || '', /^[a-zA-Z ]*$/).then(() => status = true).catch(() => (status = false, setErrors((s) => ({ ...s, first_name: 'First name should have only alphabets' })))),
//         await RegexMatch(inputs.last_name || '', /^[a-zA-Z ]*$/).then(() => status = true).catch(() => (status = false, setErrors((s) => ({ ...s, last_name: 'Last name should have only alphabets' })))),
//         await RegexMatch(inputs.email || '', /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/).then(() => status = true).catch(() => (status = false, setErrors((s) => ({ ...s, email: 'Invalid email format' })))),
//         await RegexMatch(inputs.phone_number || '', /^[0-9]{9}$/).then(() => status = true).catch(() => (status = false, setErrors((s) => ({ ...s, phone_number: 'Invalid phone number' })))),

//         await RegexMatch(inputs.first_name || '', /^$/).then(() => (status = false, setErrors((s) => ({ ...s, first_name: 'Please fill in the mandatory fields' })))).catch(() => status = true),
//         await RegexMatch(inputs.last_name || '', /^$/).then(() => (status = false, setErrors((s) => ({ ...s, last_name: 'Please fill in the mandatory fields' })))).catch(() => status = true),
//         await RegexMatch(inputs.email || '', /^$/).then(() => (status = false, setErrors((s) => ({ ...s, email: 'Please fill in the mandatory fields' })))).catch(() => status = true),
//         await RegexMatch(inputs.phone_number || '', /^$/).then(() => (status = false, setErrors((s) => ({ ...s, phone_number: 'Please fill in the mandatory fields' })))).catch(() => status = true),
//     ]).then(() => {
//         return status
//     })
// }

export const loginValidation = async (inputs, setErrors) => {
    let status = false
    return Promise.all([
        await blankCheck(inputs.username, WORD_ENTER_USERNAME).then(() => status = true).catch(err => { status = false; setErrors(s => ({ ...s, username: err })); }),
        await blankCheck(inputs.password, WORD_ENTER_LOGIN_PASSWORD).then(() => status = true).catch(err => { status = false; setErrors(s => ({ ...s, password: err })); }),
    ]).then(() => {
        return status
    }).catch((err)=>{
        return false
    })
}
export const requestDetailEntryValidation = async (inputs, setErrors) => {
    let status = false
    return Promise.all([
        await blankCheck(inputs.title, WORD_FIELD_CANT_BLANK_WARNING).then(() => status = true).catch(err => { status = false; setErrors(s => ({ ...s, title: err })); throw err }),
        await blankCheck(inputs.description, WORD_FIELD_CANT_BLANK_WARNING).then(() => status = true).catch(err => { status = false; setErrors(s => ({ ...s, description: err })); throw err }),
        await blankFileCheck(inputs.pdf, WORD_SHOULD_ATTACH_PDF_FILE).then(() => status = true).catch(err => { status = false; setErrors(s => ({ ...s, pdf: err })); throw err }),
    ]).then((e) => {
        return status
    })
}
export const singnatoriesEntryValidation = async (inputs, setErrors) => {
    console.log('validate input', inputs)


    let status = false
    return Promise.all([
        // await blankCheck(inputs.title, "The field can't be blank").then(() => status = true).catch(err => { status = false; setErrors(s => ({ ...s, title: err })); throw err }),
        // await blankCheck(inputs.description, "The field can't be blank").then(() => status = true).catch(err => { status = false; setErrors(s => ({ ...s, description: err })); throw err }),
        // await blankFileCheck(inputs.pdf, "Should attach a pdf file").then(() => status = true).catch(err => { status = false; setErrors(s => ({ ...s, pdf: err })); throw err }),
    ]).then((e) => {
        return status
    })
}
