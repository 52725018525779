import axios from "axios";

const defaultOptions = {
  headers: {
    "Content-Type": "application/json",
  },
};

// axios instance for making requests
const axiosInstance = axios.create(defaultOptions);

// request interceptor for adding token
axiosInstance.interceptors.request.use((config) => {
  // add token to request headers
  if (localStorage.getItem("token")) {
    config.headers["Authorization"] = `Bearer ${localStorage.getItem("token")}`;
  } else {
    config.headers["Authorization"] = null;
  }
  return config;
});

axiosInstance.interceptors.response.use(
  (response, dispatch) => {
    return response;
  },
  (error) => {
    if (!error.response) {
      return new Promise((resolve, reject) => {
        // reject(error);
      });
    }
    if (error?.response?.data?.token == false) {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("user_type");
      localStorage.removeItem("username");
      localStorage.removeItem("employee_id");
      localStorage.removeItem("permission");
      // window.location = "/login";
      window.location.hash = '/login';
    } else {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
  }
);

export default axiosInstance;