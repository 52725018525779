import React, { useEffect, useState } from "react";
import FormSearch from "../../components/FormSearch/FormSearch";
import Table from "../../components/Table/Table";
import PaginationRow from "../../components/PaginationRow/PaginationRow";
import Modal from "../../Tools/Modal/Modal";
import {
  addSignature,
  deleteSignature,
  getAllUser,
  getUserList,
  syncAd,
} from "../../redux/actionCreator";
import { API_BASE_URL } from "../../constants/configuration";
import { toast } from "react-toastify";
import {
  WORD_ACTIONS,
  WORD_ADD_SIGNATURE,
  WORD_AVAILABLE,
  WORD_CANCEL,
  WORD_CONFIRM,
  WORD_DO_YOU_WANT_TO_DELETE,
  WORD_NOT_AVAILABLE,
  WORD_ONLY_UPLOAD_PNG,
  WORD_SIGNATURE,
  WORD_SUBUNIT,
  WORD_SYNC_AD,
  WORD_UNIT,
  WORD_UPLOAD,
  WORD_UPLOAD_SIGNATURE,
  WORD_USERS,
  WORD_SELECT_RANK,
  WORD_SELECT_UNIT,
  WORD_SELECT_SUBUNIT,
  WORD_EMPLOYEE_ID,
  WORD_RANK,
  WORD_NAME,
  WORD_SIGNATURE_DELETED_SUCCESSFULLY,
  WORD_SIGNATURE_ADDED_SUCCESSFULLY,
} from "../../constants/translation";
import { useDispatch, useSelector } from "react-redux";
import {
  signatureData,
  signature_loader,
  signature_page_status,
  signature_totalCount,
  updateRedux,
} from "../../redux/commonReducer";
import CommonSearch from "../../components/FormSearch/CommonSearch";
import ReactPaginate from "react-paginate";
import Pagination from "../../components/CommonPagination/Pagination";

export default function AddSignature() {
  const dispatch = useDispatch();

  const signData = useSelector(signatureData);
  const pageCount = useSelector(signature_totalCount);
  const page_status = useSelector(signature_page_status);
  const loader = useSelector(signature_loader);

  const [showModal, setShowModal] = useState(false);
  const [uploadedImg, setUploadedImg] = useState();
  const [data, setData] = useState();
  const [toEditSign, setToEditSign] = useState();
  const [fileInput, setFileInput] = useState();
  const [fileInputError, setFileInputError] = useState();
  const [searchKey, setSearchKey] = useState("");
  const [limit, setLimit] = useState(10);
  const [rank, setRank] = useState({ rank: WORD_SELECT_RANK });
  const [unit, setUnit] = useState({ unit_name: WORD_SELECT_UNIT });
  const [subUnit, setSubUnit] = useState({ subunit_name: WORD_SELECT_SUBUNIT });
  const [page, setPage] = useState(0);
  const [deleteId, setDeleteID] = useState("");
  const [modalshow, setModalShow] = useState(false);
  const [signatureStatus, setSignatureStatus] = useState("");
  const [sortAscending, setSortAscending] = useState(false);
  const [sortColumn, setSortColumn] = useState("createdAt");

  const handleClose = () => setModalShow(false);

  let Titles = [
    // { title: WORD_USERS, key: "name", sort: "name" },
    // { title: WORD_UNIT, key: "unit", sort: "unit" },
    // { title: WORD_SUBUNIT, key: "subunit", sort: "subunit" },
    { title: WORD_EMPLOYEE_ID, key: "employee_id", sort: "employee_id" },
    { title: WORD_RANK, key: "rank", sort: "rank" },
    { title: WORD_NAME, key: "name", sort: "name" },
    { title: WORD_UNIT, key: "unit", sort: "unit" },
    { title: WORD_SUBUNIT, key: "subunit", sort: "subunit" },
    { title: WORD_SIGNATURE, key: "signature", sort: "signature" },
    { title: WORD_ACTIONS, key: "actions" },
  ];

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    let formData = new FormData();
    formData.append("unit", "");
    formData.append("subunit", "");
    formData.append("rank", "");
    formData.append("page", 0);
    formData.append("perPage", limit);
    formData.append("keyword", "");
    formData.append("is_available", "");
    formData.append("sort_key", sortColumn);
    formData.append("sort_order", sortAscending ? 1 : -1);
    dispatch(updateRedux({ key: "signature_loader", value: true }));
    dispatch(
      getUserList(formData, (res) => {
        dispatch(updateRedux({ key: "signature_loader", value: false }));
      })
    );
  };

  useEffect(() => {
    setFileInput();
    setFileInputError();
  }, [showModal]);

  useEffect(() => {
    if (signData) {
      setData(
        signData?.map((d, i) => {
          return {
            unit: d.unit[0].unit_name,
            subunit: d.subunit[0].subunit_name,
            name: d.name,
            username: d.username,
            signature: d.signature ? WORD_AVAILABLE : WORD_NOT_AVAILABLE,
            signature_path: d.signature,
            _id: d._id,
            employee_id: d?.employee_id,
            rank: d?.rank[0]?.rank,
          };
        })
      );
    }
  }, [signData]);

  function searchHandler(value) {
    setSearchKey(value);
  }

  function onUploadHandler() {
    setFileInputError();
    if (fileInput?.type === "image/png") {
      let formdata = new FormData();
      formdata.append("id", toEditSign.userId);
      formdata.append("signature", fileInput);
      addSignature(formdata, (res) => {
        if (res.status) {
          setShowModal(false);
          toast.success(WORD_SIGNATURE_ADDED_SUCCESSFULLY, {
            position: "bottom-center",
            autoClose: 3000,
          });
          getData();
        }
      });
    } else {
      setFileInputError(WORD_ONLY_UPLOAD_PNG);
    }
  }

  const onLimitChangeHandler = (limitValue) => {
    if (limit != limitValue) {
      setLimit(limitValue);
      setPage(0);
      let formData = new FormData();
      formData.append("unit", unit._id ?? "");
      formData.append("subunit", subUnit._id ?? "");
      formData.append("rank", rank?._id ?? "");
      formData.append("page", 0);
      formData.append("perPage", limitValue);
      formData.append("keyword", searchKey);
      formData.append("is_available", signatureStatus?.value ?? "");
      formData.append("sort_key", sortColumn);
      formData.append("sort_order", sortAscending ? 1 : -1);
      dispatch(getUserList(formData));
    }
  };

  const handlePageChange = (e) => {
    setPage(e.selected);
    if (e.selected >= 0) {
      let formData = new FormData();
      formData.append("unit", unit._id ?? "");
      formData.append("subunit", subUnit._id ?? "");
      formData.append("rank", rank?._id ?? "");
      formData.append("page", e.selected);
      formData.append("perPage", limit);
      formData.append("keyword", searchKey);
      formData.append("is_available", signatureStatus?.value ?? "");
      formData.append("sort_key", sortColumn);
      formData.append("sort_order", sortAscending ? 1 : -1);
      dispatch(getUserList(formData));
    }
  };

  const handleRemove = (id) => {
    setModalShow(true);
    setDeleteID(id);
  };

  const deleteSignatureHandler = () => {
    dispatch(
      deleteSignature(deleteId, (status) => {
        if (status) {
          let formData = new FormData();
          formData.append("unit", unit._id ?? "");
          formData.append("subunit", subUnit._id ?? "");
          formData.append("rank", rank?._id ?? "");
          formData.append("page", page);
          formData.append("perPage", limit);
          formData.append("keyword", searchKey);
          formData.append("is_available", signatureStatus?.value ?? "");
          formData.append("sort_key", sortColumn);
          formData.append("sort_order", sortAscending ? 1 : -1);
          dispatch(getUserList(formData));
          setModalShow(false);
          toast.success(WORD_SIGNATURE_DELETED_SUCCESSFULLY, {
            position: "bottom-center",
            autoClose: 3000,
          });
        }
      })
    );
  };

  const onSortHandler = (type) => {
    setSortAscending(!sortAscending);
    setSortColumn(type);
    let formData = new FormData();
    formData.append("unit", unit._id ?? "");
    formData.append("subunit", subUnit._id ?? "");
    formData.append("rank", rank?._id ?? "");
    formData.append("page", page);
    formData.append("perPage", limit);
    formData.append("keyword", searchKey);
    formData.append("is_available", signatureStatus?.value ?? "");
    formData.append("sort_key", type);
    formData.append("sort_order", !sortAscending ? 1 : -1);
    dispatch(getUserList(formData));
  };

  const onGetSyncHandler = () => {
    dispatch(syncAd());
  };

  return (
    <div id="AddSignature">
      <h1 className="page-title">{WORD_ADD_SIGNATURE}</h1>
      <div className="backButtonSection">
        <button className="back" onClick={() => onGetSyncHandler()}>
          {WORD_SYNC_AD}
        </button>
      </div>
      <section>
        <CommonSearch
          pageName="signature"
          page={page}
          setPage={setPage}
          limit={limit}
          getData={getUserList}
          rank={rank}
          setRank={setRank}
          unit={unit}
          setUnit={setUnit}
          subUnit={subUnit}
          setSubUnit={setSubUnit}
          searchKey={searchKey}
          setSearchKey={setSearchKey}
          signatureStatus={signatureStatus}
          setSignatureStatus={setSignatureStatus}
          setSortAscending={setSortAscending}
          setSortColumn={setSortColumn}
        />
      </section>
      <section>
        <PaginationRow
          page={"AddSignature"}
          onCount={(count) => onLimitChangeHandler(count)}
        />
      </section>
      <section>
        {loader ? (
          <div className="loader-div">
            <img
              className="circles-loader"
              src="/assets/loader/circles.svg"
              alt=""
            />
          </div>
        ) : (
          <>
            <Table
              page={"add signature"}
              titles={Titles}
              data={data}
              setModalShow={setShowModal}
              onEdit={(res) => setToEditSign(res)}
              handleRemove={handleRemove}
              onSortHandler={onSortHandler}
              sortColumn={sortColumn}
              sortAscending={sortAscending}
            />
            <div className="align-between">
              <p style={{ direction: "ltr", color: "#0d7b58" }}>
                {page_status}
              </p>
              <Pagination
                page={page}
                pageCount={pageCount}
                handlePageChange={handlePageChange}
              />
            </div>
          </>
        )}
      </section>
      <Modal setShow={setShowModal} show={showModal}>
        <img className="close-icon" src="" alt="" />
        <div className="sign">
          <p>{toEditSign?.username}</p>
          <span>
            {fileInput ? (
              <img src={URL.createObjectURL(fileInput)} alt="" />
            ) : (
              toEditSign?.signPng && (
                <img
                  src={`${API_BASE_URL}/signature/${toEditSign?.signPng}`}
                  alt=""
                />
              )
            )}
            {toEditSign?.signature == "Available" ? (
              <img
                className="delete-icon"
                src="./assets/icons/delete red.svg"
                onClick={() => (
                  setToEditSign((s) => ({ ...s, signPng: "" })), setFileInput()
                )}
                alt=""
              />
            ) : null}
          </span>
        </div>
        <div className="input">
          <button className="upload">
            <label htmlFor="signatureUploadBtn">
              {WORD_UPLOAD_SIGNATURE}
              <input
                type="file"
                id="signatureUploadBtn"
                hidden
                accept="image/png"
                onChange={(e) => setFileInput(e.target.files[0])}
              />
            </label>
          </button>
          <button className="save" onClick={() => onUploadHandler()}>
            {" "}
            {WORD_UPLOAD}
          </button>
          {fileInputError && <p className="instruction">{fileInputError}</p>}
        </div>
      </Modal>
      <Modal show={modalshow} centered onHide={handleClose}>
        <div id="CheckBoxModal">
          <h3 dir="ltr">{WORD_DO_YOU_WANT_TO_DELETE}</h3>
          <div className="buttons">
            <button className="confirm" onClick={deleteSignatureHandler}>
              {WORD_CONFIRM}
            </button>
            <button className="cancel" onClick={handleClose}>
              {WORD_CANCEL}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
