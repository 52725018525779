import React, { createContext, useState } from 'react'
import RequestDetailsEntry from '../../components/RequestDetailsEntry/RequestDetailsEntry'
import SignatoriesEntry from '../../components/SignatoriesEntry/SignatoriesEntry'
import { WORD_CREATE_NEW_REQUEST } from '../../constants/translation'
import { useLocation } from 'react-router'
import { useEffect } from 'react'
import { getRequestDetail } from '../../redux/actionCreator'
import { useNavigate } from "react-router-dom";

export const RequestInputContext = createContext()
export default function ManageRequests() {

    const navigate = useNavigate();

    const [page, setPage] = useState(1)
    const [inputs, setInputs] = useState(initialInputObject())
    const [inputErrors, setInputErrors] = useState({
        Signatory: [],
        Secretary: [],
        title: '',
        description: '',
        pdf: '',
    })
    const [prevData, setPrevData] = useState(null)
    const [signatoryCount, setSignatoryCount] = useState({ id: 1, value: 1 });

    function initialInputObject() {
        return {
            Signatory: [],
            Secretary: [],
            supportingFiles: [],
            title: '',
            description: '',
            pdf: '',
        }
    }


    const location = useLocation()

    useEffect(() => {
        if (location.state?.back) {
            setPage(2)
            let obj = {
                id: location.state.id,
                type: "0",
                is_archived: 0
            }
            getRequestDetail(obj, (res) => {
                setPrevData(res.request_details)
                // console.log(res.request_details.signatory.some(e=>e.is_reviewer), 'back button')
                const preData = {
                    Signatory: res.request_details.signatory.map((item, index) => ({ sl_no: index + 1, unit: item.signatory.unit[0]._id, subunit: item.signatory.subunit[0]._id, name: item.signatory._id, isReviewer: item.is_reviewer })),
                    Secretary: res.request_details.secretery.map((item, index) => ({ sl_no: index + 1, name: item.secretery._id })),
                    title: res.request_details.title,
                    description: res.request_details.description,
                    pdf: res.request_details.pdf,
                    isEditing: true,
                    isReviewer: res.request_details.signatory.some(e => e.is_reviewer)
                }
                setInputs(preData)
                // console.log(preData, "125896")
                setSignatoryCount({ id: preData?.Signatory?.length, value: preData?.Signatory?.length })
                handleData({ id: preData?.Signatory?.length, value: preData?.Signatory?.length }, preData)
                // console.log({ preData })
            });
        }
    }, []);

    const handleData = (count, data) => {
        // console.log("h")
        let inputdata = data;
        if (inputdata?.Signatory?.length !== parseInt(count.id)) {
            inputdata.Signatory = inputdata?.Signatory?.slice(0, parseInt(count.id));
            setInputs(inputdata);
        }
        if (prevData?.signatory?.length > parseInt(count.id)) {
            setPrevData({
                ...prevData,
                signatory: prevData?.signatory?.slice(0, parseInt(count.id))
            })
        }
    };

    //   console.log({prevData})
    return (
        <RequestInputContext.Provider value={{ setInputErrors, inputErrors, setInputs, inputs, initialInputObject }}>
            <div id='ManageRequests'>
                <h1 className='page-title'>{WORD_CREATE_NEW_REQUEST}</h1>
                <section>
                    {
                        page === 1 ? <RequestDetailsEntry
                            setPage={setPage}
                            navigate={navigate}
                            setSignatoryCount={setSignatoryCount}
                            setPrevData={setPrevData}
                            handleData={handleData}
                        /> :
                            page === 2 ? <SignatoriesEntry
                                setPage={setPage}
                                PreFilledData={prevData}
                                setPrevData={setPrevData}
                                signatoryCount={signatoryCount}
                                setSignatoryCount={setSignatoryCount}
                                handleData={handleData}
                            /> : null
                    }
                </section>
            </div>
        </RequestInputContext.Provider>
    )
}