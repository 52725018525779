import React, { useEffect, useState } from "react";
import "./Table.scss";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  WORD_DELETE_SIGNATURE,
  WORD_UPDATE_SIGNATURE,
} from "../../constants/translation";

export default function Table({
  page,
  titles,
  data,
  setModalShow,
  onEdit,
  onCheckboxChange,
  handleRemove,
  onSortHandler,
  sortColumn,
  sortAscending,
}) {
  let flag =
    page === "pending requests"
      ? 2
      : page === "add signature"
        ? 3
        : page === "reports" || page === "dashboard" || page === "archived" || page === "tech_archived"
          ? 1
          : 0;

  const navigate = useNavigate();

  // console.log({ data })
  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {props.label}
    </Tooltip>
  );

  return (
    <div id="Table">
      <section className="table table-responsive-sm">
        <table>
          <thead>
            <tr>
              {titles?.map((title, i) => {
                return (
                  <th
                    key={i}
                    style={{ width: title.width }}
                    onClick={
                      title.sort != undefined
                        ? () => onSortHandler(title.sort)
                        : () => { }
                    }
                    className={
                      title.sort != undefined
                        ? `cursor-pointer 
                          ${sortColumn == title.sort && sortAscending == true
                          ? "active"
                          : sortColumn == title.sort &&
                            sortAscending == false
                            ? "inactive"
                            : ""
                        }`
                        : ""
                    }
                  >
                    {title.title}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map((datom, i) => {
                return (
                  <tr
                    key={i}
                    className={`${flag === 1
                      ? datom?.currentStatus == "pending" || datom?.currentStatus == "Pending"
                        ? "pending"
                        : datom?.currentStatus == "approved"
                          ? "approved"
                          : datom?.currentStatus == "returned"
                            ? "returned"
                            : datom?.currentStatus == "rejected"
                              ? "rejected"
                              : datom?.currentStatus == "Closed"
                                ? "closed"
                                : ""
                      : ""
                      } `}
                  >
                    {titles?.map((title, si) => {
                      return (
                        <td
                          key={si}
                          dir={title.key === "createdAt" || title.key === "deleted_at" ? "ltr" : null}
                        // className={`${title.key === "actions" || title.key ==="handler" ? "d-flex": ""}`}
                        >
                          {title.key === "actions" && flag === 1 ? (
                            <>
                              <img
                                className="eye"
                                src="./assets/icons/eye.svg"
                                alt=""
                                onClick={() =>
                                  navigate(`request_details/${datom._id}`)
                                }
                              />
                              {/* <img className='delete' src="./assets/icons/delete1.svg" alt="" /> */}
                              {/* <img className='eye' src="./assets/icons/bell.svg" alt="" /> */}
                              {/* <img className='eye' src="./assets/icons/eye.svg" alt="" onClick={() => navigate(`request_details/${datom._id}`)} /> */}
                              {/* <img className='delete' src="./assets/icons/delete1.svg" alt="" /> */}
                              {/* <img className='eye' src="./assets/icons/bell.svg" alt="" /> */}
                            </>
                          ) : title.key === "actions" && flag === 4 ? (
                            <img
                              className="eye"
                              src="./assets/icons/eye.svg"
                              alt=""
                            />
                          )
                            : title.key === "action" && flag === 2 ? (
                              <img
                                className="eye"
                                src="./assets/icons/eye.svg"
                                alt=""
                                onClick={() =>
                                  navigate(`request_details/${datom._id}`)
                                }
                              />
                            )
                              : title.key === "actions" && flag === 3 ? (
                                <>
                                  <OverlayTrigger
                                    placement="top"
                                    delay={{ show: 250, hide: 400 }}
                                    overlay={renderTooltip({
                                      label: WORD_UPDATE_SIGNATURE,
                                    })}
                                  >
                                    <img
                                      className="edit"
                                      src="./assets/icons/edit.svg"
                                      alt=""
                                      onClick={() => (
                                        onEdit({
                                          userId: datom._id,
                                          signPng: datom.signature_path,
                                          username: datom.username,
                                          signature: datom.signature,
                                        }),
                                        setModalShow(true)
                                      )}
                                    />
                                  </OverlayTrigger>
                                  {(datom?.signature == "Available" || datom?.signature == "متاح") ? (
                                    <OverlayTrigger
                                      placement="top"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={renderTooltip({
                                        label: WORD_DELETE_SIGNATURE,
                                      })}
                                    >
                                      <img
                                        className="delete dlted"
                                        src="./assets/icons/delete1.svg"
                                        alt=""
                                        onClick={() => handleRemove(datom._id)}
                                      />
                                    </OverlayTrigger>
                                  ) : null}
                                </>
                              ) : title.key === "created_by" ? (
                                datom[title.key][0].name
                              ) : title.key === "secretary_permission" ? (
                                <input
                                  type="checkbox"
                                  checked={datom.is_secretery}
                                  onChange={(e) =>
                                    onCheckboxChange(
                                      e.target.checked,
                                      datom._id,
                                      datom.name
                                    )
                                  }
                                />
                              ) : title.key === "createdAt" ? (
                                moment(datom[title.key]).format("DD MMM yyyy")
                              ) : title.key === "deleted_at" ? (
                                datom[title.key] && moment(datom[title.key]).format("DD MMM yyyy")
                              ) : title.key === "deleted_by" ? (
                                datom[title.key] && datom[title.key][0].name
                              ) : (
                                datom[title.key]
                              )}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
          </tbody>
        </table>
      </section>
    </div>
  );
}
