import React, { useEffect, useState } from "react";
import Table from "../../components/Table/Table";
import PaginationRow from "../../components/PaginationRow/PaginationRow";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteTechSupportRequest,
  getTechSupportRequests,
} from "../../redux/actionCreator";
import {
  tech_pending_loader,
  updateRedux,
  tech_pending_data,
  tech_pending_total_pages,
  tech_pending_page_status,
} from "../../redux/commonReducer";
import {
  WORD_ACTION,
  WORD_CREATED_DATE,
  WORD_PENDING_REQUEST,
  WORD_REQUESTER,
  WORD_REQUEST_ID,
  WORD_TITLE,
  WORD_SELECT_RANK,
  WORD_SELECT_UNIT,
  WORD_SELECT_SUBUNIT,
  WORD_DELETE_CONFIRMATION,
  WORD_YES,
  WORD_NO,
  WORD_DELETED_SUCCESSFULLY,
  WORD_STATUS,
  WORD_SEARCH_KEYWORD,
  WORD_SEARCH_KEYWORD_TEXT,
  WORD_TECH_SUPPORT_PENDING_REQUEST,
} from "../../constants/translation";
import Pagination from "../../components/CommonPagination/Pagination";
import CommonSearch from "../../components/FormSearch/CommonSearch";
import { useLocation, useNavigate } from "react-router-dom";
import Modal from "../../Tools/Modal/Modal";
import { toast } from "react-toastify";
import { modulePermission } from "../../utils/helpers";

const TechPendingRequest = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  let userId = JSON.parse(localStorage.getItem("user"))?._id;
  const userType = localStorage.getItem("user_type");

  const pending_data = useSelector(tech_pending_data);
  const pageCount = useSelector(tech_pending_total_pages);
  const page_status = useSelector(tech_pending_page_status);
  const loader = useSelector(tech_pending_loader);

  const [modalRequestshow, setModalRequestShow] = useState(false);
  const [delId, setDelId] = useState("");
  const handleClose = () => setModalRequestShow(false);

  const deleteRequestCurrent = (id) => {
    setDelId(id);
    setModalRequestShow(true);
  };

  let Titles = [
    { title: WORD_REQUEST_ID, key: "reqId", width: "150px", sort: "requestId" },
    { title: WORD_TITLE, key: "title", sort: "title" },
    {
      title: WORD_REQUESTER,
      key: "created_by",
      width: "180px",
      sort: "createdBy",
    },
    {
      title: WORD_STATUS,
      key: "currentStatus",
      width: "180px",
      sort: "currentStatus",
    },
    {
      title: WORD_CREATED_DATE,
      key: "createdAt",
      width: "150px",
      sort: "createdAt",
    },
    { title: WORD_ACTION, key: "handler", width: "130px" },
  ];

  const [data, setData] = useState();
  const [searchKey, setSearchKey] = useState("");
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [rank, setRank] = useState({ rank: WORD_SELECT_RANK });
  const [unit, setUnit] = useState({ unit_name: WORD_SELECT_UNIT });
  const [subUnit, setSubUnit] = useState({ subunit_name: WORD_SELECT_SUBUNIT });
  const [pendingStatus, setPendingStatus] = useState("");
  const [sortAscending, setSortAscending] = useState(false);
  const [sortColumn, setSortColumn] = useState("createdAt");

  useEffect(() => {
    let formData = new FormData();
    formData.append("unit", "");
    formData.append("subunit", "");
    formData.append("rank", "");
    formData.append("page", 0);
    formData.append("perPage", limit);
    formData.append("currentStatus", "");
    formData.append("keyword", "");
    formData.append("sort_key", sortColumn);
    formData.append("sort_order", sortAscending ? 1 : -1);
    dispatch(updateRedux({ key: "tech_pending_loader", value: true }));
    dispatch(
      getTechSupportRequests(formData, (res) => {
        dispatch(updateRedux({ key: "tech_pending_loader", value: false }));
      })
    );
  }, []);

  useEffect(() => {
    setData([]);
    if (pending_data) {
      pending_data?.forEach((item) => {
        setData((s) => [
          ...s,
          {
            reqId: item.reqId,
            title: item.title,
            createdAt: item.createdAt,
            _id: item._id,
            created_by: item?.created_by,
            currentStatus: item?.currentStatus,
            handler: (
              <div className="d-flex align-items-center justify-content-right">
                <img
                  className="eye"
                  src="./assets/icons/eye.svg"
                  alt="view"
                  onClick={() => navigate(`request_details/${item?._id}`)}
                />
                {userType == "super_admin" ||
                (item?.created_by[0]._id == userId &&
                  item?.currentStatus != "Closed") ? (
                  <img
                    className="delete"
                    src="./assets/icons/delete1.svg"
                    alt="delete"
                    onClick={() => deleteRequestCurrent(item?._id)}
                  />
                ) : null}
              </div>
            ),
          },
        ]);
      });
    }
  }, [pending_data]);

  useEffect(() => {
    localStorage.setItem("page_url", location.pathname);
  }, []);

  const onLimitChangeHandler = (limitValue) => {
    if (limit != limitValue) {
      setLimit(limitValue);
      setPage(0);
      let formData = new FormData();
      formData.append("unit", unit?._id ?? "");
      formData.append("subunit", subUnit?._id ?? "");
      formData.append("rank", rank?._id ?? "");
      formData.append("page", 0);
      formData.append("perPage", limitValue);
      formData.append("keyword", searchKey);
      formData.append("currentStatus", pendingStatus?.value ?? "");
      formData.append("sort_key", sortColumn);
      formData.append("sort_order", sortAscending ? 1 : -1);
      dispatch(getTechSupportRequests(formData));
    }
  };

  const handlePageChange = (e) => {
    setPage(e.selected);
    if (e.selected >= 0) {
      let formData = new FormData();
      formData.append("unit", unit?._id ?? "");
      formData.append("subunit", subUnit?._id ?? "");
      formData.append("rank", rank?._id ?? "");
      formData.append("page", e.selected);
      formData.append("perPage", limit);
      formData.append("keyword", searchKey);
      formData.append("currentStatus", pendingStatus?.value ?? "");
      formData.append("sort_key", sortColumn);
      formData.append("sort_order", sortAscending ? 1 : -1);
      dispatch(getTechSupportRequests(formData));
    }
  };

  const onSortHandler = (type) => {
    setSortAscending(!sortAscending);
    setSortColumn(type);
    let formData = new FormData();
    formData.append("unit", unit?._id ?? "");
    formData.append("subunit", subUnit?._id ?? "");
    formData.append("rank", rank?._id ?? "");
    formData.append("page", page);
    formData.append("perPage", limit);
    formData.append("keyword", searchKey);
    formData.append("currentStatus", pendingStatus?.value ?? "");
    formData.append("sort_key", type);
    formData.append("sort_order", !sortAscending ? 1 : -1);
    dispatch(getTechSupportRequests(formData));
  };

  const onLeaveHandler = () => {
    setModalRequestShow(false);
    dispatch(
      deleteTechSupportRequest(delId, () => {
        toast.success(WORD_DELETED_SUCCESSFULLY, {
          position: "bottom-center",
          autoClose: 3000,
        });

        let formData = new FormData();
        formData.append("unit", unit?._id ?? "");
        formData.append("subunit", subUnit?._id ?? "");
        formData.append("rank", rank?._id ?? "");
        formData.append("page", page);
        formData.append("perPage", limit);
        formData.append("keyword", searchKey);
        formData.append("currentStatus", pendingStatus?.value ?? "");
        formData.append("sort_key", sortColumn);
        formData.append("sort_order", sortAscending ? 1 : -1);
        dispatch(getTechSupportRequests(formData));
      })
    );
  };

  return (
    <div id="PendingRequests">
      <h1 className="page-title">{WORD_TECH_SUPPORT_PENDING_REQUEST}</h1>
      <section>
        {modulePermission()?.includes("techSupportPermission") ? (
          <CommonSearch
            pageName="tech_pending"
            page={page}
            setPage={setPage}
            limit={limit}
            getData={getTechSupportRequests}
            rank={rank}
            setRank={setRank}
            unit={unit}
            setUnit={setUnit}
            subUnit={subUnit}
            setSubUnit={setSubUnit}
            searchKey={searchKey}
            setSearchKey={setSearchKey}
            // pendingStatus={pendingStatus}
            // setPendingStatus={setPendingStatus}
            setSortAscending={setSortAscending}
            setSortColumn={setSortColumn}
            searchKeyPlaceholder={WORD_SEARCH_KEYWORD}
          />
        ) : (
          <CommonSearch
            pageName="tech_pending"
            page={page}
            setPage={setPage}
            limit={limit}
            getData={getTechSupportRequests}
            searchKey={searchKey}
            setSearchKey={setSearchKey}
            setSortAscending={setSortAscending}
            setSortColumn={setSortColumn}
            searchKeyPlaceholder={WORD_SEARCH_KEYWORD_TEXT}
          />
        )}
      </section>
      <section>
        <PaginationRow
          page={"tech_pending"}
          onCount={(count) => onLimitChangeHandler(count)}
        />
      </section>
      <section>
        {loader ? (
          <div className="loader-div">
            <img
              className="circles-loader"
              src="/assets/loader/circles.svg"
              alt=""
            />
          </div>
        ) : (
          <>
            <Table
              page="pending requests"
              titles={Titles}
              data={data}
              onSortHandler={onSortHandler}
              sortColumn={sortColumn}
              sortAscending={sortAscending}
            />
            <div className="align-between">
              <p style={{ direction: "ltr", color: "#0d7b58" }}>
                {page_status}
              </p>
              <Pagination
                page={page}
                pageCount={pageCount}
                handlePageChange={handlePageChange}
              />
            </div>
          </>
        )}

        <Modal show={modalRequestshow} centered onHide={handleClose}>
          <div id="CheckBoxModal">
            <h3 dir="ltr">{WORD_DELETE_CONFIRMATION}</h3>
            <div className="buttons">
              <button className="confirm" onClick={onLeaveHandler}>
                {WORD_YES}
              </button>
              <button className="cancel" onClick={handleClose}>
                {WORD_NO}
              </button>
            </div>
          </div>
        </Modal>
      </section>
    </div>
  );
};
export default TechPendingRequest;
