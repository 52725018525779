import React, { useEffect, useState } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

// function ProtectedRoute() {
//     console.log("123")
//     const token = localStorage.getItem('token')
//     const { pathname } = useLocation()

//     let auth = { 'token': token }

//     if (pathname === '/login' && auth.token) {
//         return <Navigate to={'/dashboard'} />
//     } else if (pathname !== '/login' && !auth.token) {
//         return <Navigate to={'/login'} />
//     } else {
//         return <Outlet />
//     }
// }

// export default ProtectedRoute

function ProtectedRoute() {
    const token = localStorage.getItem('token')
  
      // let auth = {'token':token}
      let auth = {'token': token}
     
    return (
      auth.token ? <Outlet/> : <Navigate to='/login'/>
    )
  }
  
  export default ProtectedRoute