import React, { Fragment, useState, useEffect } from "react";
import "./NavigationBar.scss";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { getModulePermission, logout } from "../../redux/actionCreator";
import { useDispatch, useSelector } from "react-redux";
import { User, pdf_open_status, updateRedux } from "../../redux/commonReducer";
import { modulePermission } from "../../utils/helpers";
import {
  WORD_ADD_SIGNATURE,
  WORD_ASSIGN_ROLE,
  WORD_CLOSED_REQUEST,
  WORD_CREATE_REQUEST,
  WORD_DASHBOARD,
  WORD_INPROGRESS_REQUEST,
  WORD_MANAGE_REQUEST,
  WORD_MANAGE_USER_ROLE,
  WORD_PENDING_REQUEST,
  WORD_REPORTS,
  WORD_LEAVE,
  WORD_STAY,
  WORD_LEAVE_CONFIRMATION,
  WORD_ARCHIVED_REQUEST,
  WORD_TECH_SUPPORT,
  WORD_E_SIGN_REPORT,
  WORD_TECH_SUPPORT_REPORT,
  WORD_NEW_REQUEST,
} from "../../constants/translation";
import Modal from "../../Tools/Modal/Modal";

export default function NavigationBar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const user = JSON.parse(localStorage.getItem("user"));
  const is_tech_support = localStorage.getItem("is_tech_support")

  const pdf_status = useSelector(pdf_open_status);

  const [selected, setSelected] = useState({ item: 0, subItem: null });
  const [modalshow, setModalShow] = useState(false);
  const [path, setPath] = useState("");
  const [item, setItem] = useState("");
  const [subitem, setSubitem] = useState("");

  const handleClose = () => setModalShow(false);

  const logoutHandler = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    navigate("/login");
  };

  useEffect(() => {
    let path = location.pathname.split("/");
    if (!user?.is_secretery && path[1] === "") {
      navigate("manage_requests/pending_requests");
      setSelected({ item: 1, subItem: 1 });
    }
    if (path[1]) {
      switch (path[1]) {
        case "":
          setSelected({ item: 0, subItem: 0 });
          break;
        case "manage_requests":
          setSelected({ item: 1, subItem: 0 });
          break;
        case "manage_user_roles":
          setSelected({ item: 3, subItem: 0 });
          break;
        case "tech_support":
          setSelected({ item: 2, subItem: 0 });
          break;
        case "reports":
          setSelected({ item: 4, subItem: 0 });
          break;
        case "tech_support_reports":
          setSelected({ item: 4, subItem: 1 });
          break;
        default:
      }
    }
    if (path[2]) {
      switch (path[2]) {
        case "create_request":
          setSelected((s) => ({ ...s, subItem: 0 }));
          break;
        case "pending_requests":
          setSelected((s) => ({ ...s, subItem: 1 }));
          break;
        case "inprogress_requests":
          setSelected((s) => ({ ...s, subItem: 2 }));
          break;
        case "closed_requests":
          {
            path[1] === "tech_support"
              ? setSelected((s) => ({ ...s, subItem: 2 }))
              : setSelected((s) => ({ ...s, subItem: 3 }));
          }
          break;
        case "add_signature":
          setSelected((s) => ({ ...s, subItem: 0 }));
          break;
        case "assign_role":
          setSelected((s) => ({ ...s, subItem: 1 }));
          break;
        case "archived_requests":
          path[1] === 'tech_support'
            ? setSelected((s) => ({ ...s, subItem: 3 }))
            : setSelected((s) => ({ ...s, subItem: 4 }));
          break;
      }
    }
  }, []);

  useEffect(() => {
    switch (location.pathname) {
      case '/manage_requests/pending_requests': setSelected({ item: 1, subItem: 1 }); break;
      case '/tech_support/pending_requests': setSelected({ item: 2, subItem: 1 }); break;
      default:
    }
  }, [location.pathname])

  useEffect(() => {
    dispatch(getModulePermission());
  }, []);

  const menuItems = [
    {
      title: WORD_DASHBOARD,
      icon: "dashboard",
      access: "create_request",
      navPath: "/",
    },
    {
      title: WORD_MANAGE_REQUEST,
      icon: "manage_requests",
      access: "",
      navPath: "",
      subItems: [
        {
          title: WORD_CREATE_REQUEST,
          icon: "create_request",
          access: "create_request",
          navPath: "manage_requests/create_request",
        },
        {
          title: WORD_PENDING_REQUEST,
          icon: "pending_requests",
          access: "",
          navPath: "manage_requests/pending_requests",
        },
        {
          title: WORD_INPROGRESS_REQUEST,
          icon: "inprogress",
          access: "",
          navPath: "manage_requests/inprogress_requests",
        },
        {
          title: WORD_CLOSED_REQUEST,
          icon: "closed_requests",
          access: "",
          navPath: "manage_requests/closed_requests",
        },
        {
          title: WORD_ARCHIVED_REQUEST,
          icon: "archive",
          access: ["create_request", "archives"],
          navPath: "manage_requests/archived_requests",
        },
      ],
    },
    {
      title: WORD_TECH_SUPPORT,
      icon: "manage_requests",
      access: "",
      navPath: "",
      subItems: [
        {
          title: WORD_NEW_REQUEST,
          icon: "create_request",
          access: "techSupportUser",
          navPath: "tech_support/create_request",
        },
        {
          title: WORD_PENDING_REQUEST,
          icon: "pending_requests",
          access: "",
          navPath: "tech_support/pending_requests",
        },
        {
          title: WORD_CLOSED_REQUEST,
          icon: "closed_requests",
          access: "",
          navPath: "tech_support/closed_requests",
        },
        {
          title: WORD_ARCHIVED_REQUEST,
          icon: "archive",
          access: ["techSupportUser", "tech_supportArchives"],
          navPath: "tech_support/archived_requests",
        },
      ],
    },
    {
      title: WORD_MANAGE_USER_ROLE,
      icon: "manage_user_roles",
      access: ["add_signature", "assign_role"],
      navPath: "",
      subItems: [
        {
          title: WORD_ADD_SIGNATURE,
          icon: "add-signature",
          access: "add_signature",
          navPath: "manage_user_roles/add_signature",
        },
        {
          title: WORD_ASSIGN_ROLE,
          icon: "assign-role",
          access: "assign_role",
          navPath: "manage_user_roles/assign_role",
        },
      ],
    },

    // {
    //   title: WORD_REPORTS,
    //   icon: "reports",
    //   access: "reports",
    //   navPath: "reports",
    // },
    {
      title: WORD_REPORTS,
      icon: "reports",
      access: ["reports", "tech_supportReport"],
      navPath: "",
      subItems: [
        {
          title: WORD_E_SIGN_REPORT,
          icon: "report-file-svgrepo-com",
          access: "reports",
          navPath: "reports",
        },
        {
          title: WORD_TECH_SUPPORT_REPORT,
          icon: "report-file-svgrepo-com",
          access: "tech_supportReport",
          navPath: "tech_support_reports",
        },
      ],
    },
  ];

  const permissionHandler = (permissionKey) => {
    if (Array.isArray(permissionKey)) {
      return permissionKey.some((e) => modulePermission()?.includes(e));
    }
  };

  const onLeaveHandler = () => {
    handleClose();
    if (path) {
      navigate(path);
    }
    setSelected({ item: item, subItem: subitem });
    dispatch(updateRedux({ key: "pdf_open_status", value: false }));
    localStorage.removeItem("dashboardSearch");
    localStorage.removeItem("self");
  };

  return (
    <div id="NavigationBar">
      <header>
        <img src="./assets/images/TSD logo.png" alt="" />
        <hr />
      </header>
      <ul>
        {menuItems.map((item, i) => {
          return (
            <Fragment key={i}>
              {modulePermission()?.includes(item?.access) ||
              permissionHandler(item.access) ? (
                <NavLink
                  to={!pdf_status && item.navPath ? item.navPath : null}
                  onClick={() => {
                    if (pdf_status) {
                      setModalShow(true);
                      setPath(item.navPath);
                      setItem(i);
                      setSubitem(null);
                    } else {
                      setSelected((s) => {
                        if (s.item === i) {
                          return { item: null, subitem: null };
                        } else {
                          return { item: i, subItem: null };
                        }
                      });
                      localStorage.removeItem("dashboardSearch");
                      localStorage.removeItem("self");
                    }
                  }}
                >
                  <li
                    className={"item" + (selected.item === i ? " active" : "")}
                  >
                    <img src={`./assets/icons/${item.icon}.svg`} alt="" />
                    <p>{item.title}</p>
                  </li>
                </NavLink>
              ) : null}
              {item.subItems && selected.item === i && (
                <ul>
                  {item.subItems.map((subItem, si) => {
                    return modulePermission()?.includes(subItem?.access) ||
                      permissionHandler(subItem.access) ? (
                      <NavLink
                        NavLink
                        key={si}
                        to={!pdf_status && subItem.navPath}
                        onClick={() => {
                          if (pdf_status) {
                            setModalShow(true);
                            setPath(subItem.navPath);
                            setItem(i);
                            setSubitem(si);
                          } else {
                            setSelected({ item: i, subItem: si });
                            localStorage.removeItem("dashboardSearch");
                            localStorage.removeItem("self");
                          }
                        }}
                      >
                        <li
                          className={
                            "subitem" +
                            (selected.subItem === si ? " active" : "")
                          }
                        >
                          <img
                            src={`./assets/icons/${subItem.icon}.svg`}
                            alt=""
                          />
                          <p>{subItem.title}</p>
                        </li>
                      </NavLink>
                    ) : null;
                    // : null
                  })}
                </ul>
              )}
            </Fragment>
          );
        })}
        {/* <NavLink onClick={() => logoutHandler()}>
          <li>
            <img src="./assets/icons/logout.svg" alt="" />
            <p>Logout</p>
          </li>
        </NavLink> */}
      </ul>
      <Modal show={modalshow} centered onHide={handleClose}>
        <div id="CheckBoxModal">
          <h3 dir="ltr">{WORD_LEAVE_CONFIRMATION}</h3>
          <div className="buttons">
            <button className="cancel" onClick={onLeaveHandler}>
              {WORD_LEAVE}
            </button>
            <button className="confirm" onClick={handleClose}>
              {WORD_STAY}
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}
