import React from 'react'
import './PageSubTitle.scss'

export default function PageSubTitle({ name }) {
    return (
        <div id='PageSubTitle'>
            <h2 className='title'>{name}</h2>
            <hr />
        </div>
    )
}
