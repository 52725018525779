import axios from 'axios';
import { toast } from 'react-toastify';
import { API_BASE_URL } from "../constants/configuration";
import { updateRedux } from './commonReducer';
import axiosFormInstance from '../utils/axiosFormInterceptor';
import axiosInstance from '../utils/axiosInterceptor';
import { WORD_ACTIVE_DIRECTORY_SYNC_SUCCESSFULLY } from '../constants/translation';

const formHeader = {
  headers: {
    'Content-Type': 'multipart/form-data',
    'Authorization': `Bearer ${localStorage.getItem('token')}`
  },
}

const header = {
  headers: {
    'Authorization': `Bearer ${localStorage.getItem('token')}`
  },
}

export const login = (data, callback) => (dispatch) => {
  // console.log(`${API_BASE_URL}`)
  axios.post(`${API_BASE_URL}admin/login`, data).then(res => {
    // console.log(res.data)
    if (res.data.status) {
      localStorage.setItem('token', res.data.accessToken)
      localStorage.setItem('user', JSON.stringify(res.data))
      localStorage.setItem('user_type', res.data.user_type)
      localStorage.setItem('is_secretery', res.data.is_secretery)
      localStorage.setItem('username', res.data.username)
      localStorage.setItem('employee_id', res.data.employee_id)
      localStorage.setItem('is_tech_support', res.data.is_tech_support)
      dispatch(updateRedux({ key: 'user', value: res.data }))
      dispatch(updateRedux({ key: 'employee_id', value: res.data.employee_id }))
      if (callback) { callback(res.data) }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if (callback) { callback(res.data) }
    }
  }).catch((err) => { console.log(err) })
}

// export const logout = () => () => {
//   localStorage.removeItem('token')
//   localStorage.removeItem('user')
// }

export const getAllUnits = (data, callback) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}admin/getUnit`).then(res => {
    // console.log('getAllUnits', res.data)
    if (res.data.status == true) {
      dispatch(updateRedux({ key: 'unit_details', value: res.data.unit_details }))
      if (callback) { callback(res.data.unit_details) }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if (callback) { callback(res.data.status) }
    }
  }).catch((err) => { console.log(err) })
}

export const getAllSubUnits = (id, callback) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}admin/getSubunit/${id}`).then(res => {
    // console.log('getAllSubunits', res)
    if (res.data.status == true) {
      dispatch(updateRedux({ key: 'subunit_details', value: res.data.subunit_details }))
      if (callback) { callback(res.data) }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if (callback) { callback(res.data.status) }
    }
  }).catch((err) => { console.log(err) })
}

export const getUser = (formData, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/getUser`, formData).then(res => {
    if (res.data.status == true) {
      dispatch(updateRedux({ key: 'userList', value: res.data.data }))
      if (callback) { callback(res.data.data) }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if (callback) { callback(res.data.data) }
    }
  }).catch((err) => { console.log(err) })
}

export const createRequest = (data, callback) => {
  // console.log('createRequest', data)
  axiosFormInstance.post(`${API_BASE_URL}admin/addRequest`, data).then(res => {
    // console.log('createRequest', res)
    if (res.data.status == true) {
      // dispatch(updateRedux({ key: 'subunits', value: res.data.subunit_details }))
      if (callback) { callback(res.data) }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if (callback) { callback(res.data.status) }
    }
  }).catch((err) => { console.log(err) })
}

export const updateRequest = (data, callback) => {
  // console.log('createRequest', data)
  axiosFormInstance.post(`${API_BASE_URL}admin/updateRequest`, data).then(res => {
    // console.log('createRequest', res)
    if (res.data.status == true) {
      // dispatch(updateRedux({ key: 'subunits', value: res.data.subunit_details }))
      if (callback) { callback(res.data) }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if (callback) { callback(res.data.status) }
    }
  }).catch((err) => { console.log(err) })
}

export const deleteRequest = (delId, callback) => (dispatch) => {
  const data = {
    id: delId
  };
  axiosInstance.post(API_BASE_URL + 'admin/deleteRequest', data).then((res) => {

    if (res.data.status) {
      if (callback) {
        callback()
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })

    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getPendingRequest = (formData, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/getPendingRequest`, formData).then(res => {
    if (res.data.status == true) {
      dispatch(updateRedux({ key: 'pending_details', value: res.data.request_details }))
      dispatch(updateRedux({ key: 'pending_total_pages', value: res.data.pages }))
      dispatch(updateRedux({ key: 'pending_page_status', value: res.data.page_status }))
      dispatch(updateRedux({ key: 'pending_page', value: res.data.page }))
      callback && callback()
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if (callback) { callback(res.data.status) }
    }
  }).catch((err) => { console.log(err) })
}

export const inProgressRequest = (formData, callback) => (dispatch) => {
  // console.log(formData)
  axiosFormInstance.post(`${API_BASE_URL}admin/inProgressRequest`, formData).then(res => {
    // console.log('inProgressRequest', res.data)
    if (res.data.status == true) {
      // dispatch(updateRedux({ key: 'subunits', value: res.data.subunit_details }))
      dispatch(updateRedux({ key: 'inprogress_details', value: res.data.request_details }))
      dispatch(updateRedux({ key: 'inprogress_total_pages', value: res.data.pages }))
      dispatch(updateRedux({ key: 'inprogress_page_status', value: res.data.page_status }))
      dispatch(updateRedux({ key: 'inprogress_page', value: res.data.page }))
      callback && callback(res.data)
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if (callback) { callback(res.data.status) }
    }
  }).catch((err) => { console.log(err) })
}

export const getRequestDetail = (data, callback) => {
  axiosInstance.post(`${API_BASE_URL}admin/getRequestDetail`, data).then(res => {
    if (res.data.status == true) {
      // console.log("no")
      if (callback) {
        // console.log("if")
        callback(res.data)
      }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
    }
  }).catch((err) => { console.log(err) })
}

export const getRequest = (formData, callback) => (dispatch) => {
  // console.log('reqid', id)
  axiosFormInstance.post(`${API_BASE_URL}admin/getRequest`, formData).then(res => {
    // console.log('getRequest', res.data)
    if (res.data.status == true) {
      // dispatch(updateRedux({ key: 'subunits', value: res.data.subunit_details }))
      dispatch(updateRedux({ key: 'dashboard_details', value: res.data.request_details }))
      dispatch(updateRedux({ key: 'dashboard_total_pages', value: res.data.pages }))
      dispatch(updateRedux({ key: 'dashboard_page_status', value: res.data.page_status }))
      dispatch(updateRedux({ key: 'dashboard_page', value: res.data.page }))
      dispatch(updateRedux({ key: 'dashboard_openCount', value: res.data.openCount }))
      dispatch(updateRedux({ key: 'dashboard_approvedCount', value: res.data.approvedCount }))
      dispatch(updateRedux({ key: 'dashboard_rejectedCount', value: res.data.rejectedCount }))
      dispatch(updateRedux({ key: 'dashboard_pendingCount', value: res.data.pendingCount }))
      dispatch(updateRedux({ key: 'dashboard_returnedCount', value: res.data.returnedCount }))
      dispatch(updateRedux({ key: 'dashboard_closedCount', value: res.data.closedCount }))
      dispatch(updateRedux({ key: 'dashboard_inprogressCount', value: res.data.inProgressCount }))
      dispatch(updateRedux({ key: 'self_openCount', value: res.data.selfOpenCount }))
      dispatch(updateRedux({ key: 'self_approvedCount', value: res.data.selfApprovedCount }))
      dispatch(updateRedux({ key: 'self_rejectedCount', value: res.data.selfRejectedCount }))
      dispatch(updateRedux({ key: 'self_pendingCount', value: res.data.selfPendingCount }))
      dispatch(updateRedux({ key: 'self_returnedCount', value: res.data.selfReturnedCount }))
      dispatch(updateRedux({ key: 'self_inprogressCount', value: res.data.selfInProgressCount }))
      if (callback) { callback(res.data) }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if (callback) { callback(res.data.status) }
    }
  }).catch((err) => { console.log(err) })
}

export const approveRequest = (data, callback) => {
  axiosInstance.post(`${API_BASE_URL}admin/approveRequest`, data).then(res => {
    // console.log({ res })
    if (res.data.status == true) {
      if (callback) { callback(res.data) }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if (callback) { callback(res.data) }
    }
  }).catch((err) => { console.log(err) })
}

export const rejectRequest = (data, callback) => {
  axiosInstance.post(`${API_BASE_URL}admin/rejectRequest`, data).then(res => {
    // console.log({ res })
    if (res.data.status == true) {
      if (callback) { callback(res.data) }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if (callback) { callback(res.data) }
    }
  }).catch((err) => { console.log(err) })
}

export const returnRequest = (data, callback) => {
  axiosInstance.post(`${API_BASE_URL}admin/returnRequest`, data).then(res => {
    // console.log('response', res.data)
    if (res.data.status == true) {
      if (callback) { callback(res.data) }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if (callback) { callback(res.data) }
    }
  }).catch((err) => { console.log(err) })
}

export const assignSignatory = (data, callback) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/assignSignatory`, data).then(res => {
    // console.log('assignSignatory', res.data)
    if (res.data.status == true) {
      if (callback) { callback(res.data) }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if (callback) { callback(res.data.status) }
    }
  }).catch((err) => { console.log(err) })
}

export const updateSignatory = (data, callback) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/updateSignatory`, data).then(res => {
    // console.log('assignSignatory', res.data)
    if (res.data.status == true) {
      if (callback) { callback(res.data) }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if (callback) { callback(res.data.status) }
    }
  }).catch((err) => { console.log(err) })
}

export const submitPDF = (data, callback) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/submitPDF`, data).then(res => {
    // console.log('submitPDF', res.data)
    if (res.data.status == true) {
      if (callback) { callback(res.data) }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if (callback) { callback(res.data.status) }
    }
  }).catch((err) => { console.log(err) })
}

export const getAllUser = (data, callback) => {
  console.log('data all', data)
  axiosInstance.post(`${API_BASE_URL}admin/getAllUser`, data).then(res => {
    console.log('getAllUser', res.data.data)
    if (res.data.status == true) {
      if (callback) { callback(res.data.data) }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if (callback) { callback(res.data.status) }
    }
  }).catch((err) => { console.log(err) })
}

export const getUserList = (formData, callback) => (dispatch) => {
  // console.log('data all', data)
  axiosFormInstance.post(`${API_BASE_URL}admin/getUserList`, formData).then(res => {
    // console.log('getUserList', res.data)
    if (res.data.status == true) {
      // if (callback) { callback(res.data.data) }
      dispatch(updateRedux({ key: "signatureData", value: res.data.data }))
      dispatch(updateRedux({ key: "signature_totalCount", value: res.data.pages }))
      dispatch(updateRedux({ key: "signature_page_status", value: res.data.page_status }))
      if (callback) { callback(res.data) }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if (callback) { callback(res.data) }
    }
  }).catch((err) => { console.log(err) })
}

export const getUserData = async (id, callback) => {
  return axiosInstance.get(`${API_BASE_URL}admin/getUserData/${id}`).then(res => {
    callback(res.data)
  }).catch(err => console.log(err))
}

export const closedRequest = (formData, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/closedRequest`, formData).then(res => {
    if (res.data.status == true) {
      // dispatch(updateRedux({ key: 'subunits', value: res.data.subunit_details }))
      dispatch(updateRedux({ key: 'closed_details', value: res.data.request_details }))
      dispatch(updateRedux({ key: 'closed_total_pages', value: res.data.pages }))
      dispatch(updateRedux({ key: 'closed_page_status', value: res.data.page_status }))
      dispatch(updateRedux({ key: 'closed_page', value: res.data.page }))
      if (callback) { callback(res.data.status) }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if (callback) { callback(res.data.status) }
    }
  }).catch((err) => { console.log(err) })
}

export const manageRoles = (data, callback) => {
  // console.log(data)
  axiosInstance.post(`${API_BASE_URL}admin/manageRoles`, data).then(res => {
    // console.log('manageRoles', res)
    if (res.data.status == true) {
      // dispatch(updateRedux({ key: 'subunits', value: res.data.subunit_details }))
      if (callback) { callback(res.data) }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if (callback) { callback(res.data.status) }
    }
  }).catch((err) => { console.log(err) })
}

export const addSignature = (data, callback) => {
  // console.log(data)
  axiosFormInstance.post(`${API_BASE_URL}admin/addSignature`, data).then(res => {
    // console.log('addSignature', res.data)
    if (res.data.status == true) {
      // dispatch(updateRedux({ key: 'subunits', value: res.data.subunit_details }))
      if (callback) { callback(res.data) }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if (callback) { callback(res.data.status) }
    }
  }).catch((err) => { console.log(err) })
}

export const setPdfHeaderDetails = (data) => dispatch => {
  dispatch(updateRedux({ key: 'pdfHeaderDetails', value: data }))
}

export const setPdfHeaderSelectedBtn = (data, boxes) => dispatch => {
  data && dispatch(updateRedux({ key: 'pdfHeaderBtns', value: data }))
  boxes && dispatch(updateRedux({ key: 'selectedBoxes', value: boxes }))
}

export const getReport = (formData, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/getRequestReport`, formData).then(res => {
    if (res.data.status == true) {
      dispatch(updateRedux({ key: 'request_details', value: res.data.request_details }))
      dispatch(updateRedux({ key: 'request_total_pages', value: res.data.pages }))
      dispatch(updateRedux({ key: 'request_page_status', value: res.data.page_status }))
      dispatch(updateRedux({ key: 'request_page', value: res.data.page }))
      dispatch(updateRedux({ key: 'report_openCount', value: res.data.openCount }))
      dispatch(updateRedux({ key: 'report_approvedCount', value: res.data.approvedCount }))
      dispatch(updateRedux({ key: 'report_rejectedCount', value: res.data.rejectedCount }))
      dispatch(updateRedux({ key: 'report_pendingCount', value: res.data.pendingCount }))
      dispatch(updateRedux({ key: 'report_returnedCount', value: res.data.returnedCount }))
      dispatch(updateRedux({ key: 'report_closedCount', value: res.data.closedCount }))
      if (callback) { callback(res.data.data) }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if (callback) { callback(res.data.status) }
    }
  }).catch((err) => { console.log(err) })
}

export const exportReportData = (formData) => (dispatch) => {
  axios.post(`${API_BASE_URL}admin/exportRequestReport`, formData, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${localStorage.getItem("token")}`
    },
  }).then((res) => {
    dispatch(updateRedux({ key: "report_export_loader", value: false }));
    if (res.status == 200) {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        'request_report.xlsx',
      );
      document.body.appendChild(link);
      link.click();
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getRankData = (callback) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}admin/getRank`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "rank_details", value: res.data.rank_details }))
      if (callback) {
        callback(res.data)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const searchRoleUser = (formData, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/searchUsersRoles`, formData).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "roleUsers", value: res.data.users }))
      if (callback) {
        callback(res.data.users?.length)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getModules = () => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}admin/getModules`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "modules", value: res.data.modules }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const insertUserRoles = (formData, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/insertRoles`, formData).then(res => {
    if (res.data.status) {
      let formData = new FormData();
      formData.append("page", 0);
      dispatch(getUserRoles(formData));
      dispatch(getModulePermission())
      if (callback) {
        callback(res.data.status)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      if (callback) {
        callback(res.data.status)
      }
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getUserRoles = (formData, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/getRoles`, formData).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "userRoles", value: res.data.roles }))
      dispatch(updateRedux({ key: "rolesTotalCount", value: res.data.total_pages }))
      dispatch(updateRedux({ key: "role_page_status", value: res.data.page_status }))
      dispatch(getModulePermission())
      if (callback) {
        callback(res.data.status)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      if (callback) {
        callback(res.data.status)
      }
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const deleteUserRoles = (delId, page, search, callback) => (dispatch) => {
  const data = {
    id: delId
  };
  axiosInstance.post(API_BASE_URL + 'admin/deleteRoles', data).then((res) => {
    if (res.data.status) {
      dispatch(getModulePermission())
      if (callback) {
        callback(res.data.status)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      if (callback) {
        callback(res.data.status)
      }
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getRoleDetails = (id) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}admin/getRoleDetails/${id}`).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "editRoleDetails", value: res.data.modules }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err.response, "6")
  })
}

export const updateUserRoles = (formData, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/updateRoles`, formData).then(res => {
    if (res.data.status) {
      let formData = new FormData();
      formData.append("page", 0);
      dispatch(getUserRoles(formData));
      dispatch(getModulePermission())
      if (callback) {
        callback(res.data.status)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      if (callback) {
        callback(res.data.status)
      }
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getMultiSubunitData = (formData, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/getMultipleSubunit`, formData).then((res) => {
    if (res.data.status) {
      dispatch(updateRedux({ key: "multiSubunitLists", value: res.data.subunit_details }))
      if (callback) {
        callback(res.data.subunit_details)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getModulePermission = (callback) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}admin/getRoleUserPermissions`).then((res) => {
    if (res.data.status) {
      localStorage.setItem("permission", res.data.modules?.permissions);
      dispatch(updateRedux({ key: "permissionModules", value: res.data.modules?.permissions }))
      if (callback) {
        callback(res.data.modules?.permissions)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err, "6")
  })
}

export const secretaryRejectRequest = (data, callback) => {
  axiosInstance.post(`${API_BASE_URL}admin/rejectRequestBySecretary`, data).then(res => {
    // console.log({ res })
    if (res.data.status == true) {
      if (callback) { callback(res.data) }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if (callback) { callback(res.data) }
    }
  }).catch((err) => { console.log(err) })
}

export const secretaryReturnRequest = (data, callback) => {
  axiosInstance.post(`${API_BASE_URL}admin/returnRequestBySecretery`, data).then(res => {
    // console.log('response', res.data)
    if (res.data.status == true) {
      if (callback) { callback(res.data) }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if (callback) { callback(res.data) }
    }
  }).catch((err) => { console.log(err) })
}

export const reassignSignatory = (data, callback) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/reassignSignatory`, data).then((res) => {
    if (res.data.status) {
      if (callback) {
        callback(res.data)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const reassignSecretery = (data, callback) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/reassignSecretery`, data).then((res) => {
    if (res.data.status) {
      if (callback) {
        callback(res.data)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const deleteSignature = (userId, callback) => (dispatch) => {
  const data = {
    id: userId
  };
  axiosInstance.post(API_BASE_URL + 'admin/deleteSignature', data).then((res) => {
    if (res.data.status) {
      if (callback) {
        callback(res.data.status)
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
      if (callback) {
        callback(res.data.status)
      }
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const syncAd = () => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}admin/activeDirectorySync`).then((res) => {
    if (res.data.status) {
      let formData = new FormData();
      formData.append("unit", "");
      formData.append("subunit", "");
      formData.append("rank", "");
      formData.append("page", 0);
      formData.append("perPage", 10);
      formData.append("keyword", "");
      formData.append("is_available", "");
      formData.append("sort_key", "createdAt");
      formData.append("sort_order", -1);
      dispatch(getUserList(formData));
      toast.success(WORD_ACTIVE_DIRECTORY_SYNC_SUCCESSFULLY, {
        position: "bottom-center",
        autoClose: 3000
      })
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getArchivedRequests = (formData, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/getArchivedRequests`, formData).then(res => {
    if (res.data.status == true) {
      dispatch(updateRedux({ key: 'archived_list', value: res.data.request_details }))
      dispatch(updateRedux({ key: 'archived_total_pages', value: res.data.pages }))
      dispatch(updateRedux({ key: 'archived_page_status', value: res.data.page_status }))
      dispatch(updateRedux({ key: 'archived_page', value: res.data.page }))
      if (callback) { callback(res.data.status) }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if (callback) { callback(res.data.status) }
    }
  }).catch((err) => { console.log(err) })
}

export const addTechSupportRequest = (formData, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/addTechSupportRequest`, formData).then(res => {
    if (res.data.status == true) {
      if (callback) { callback(res.data) }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if (callback) { callback(res.data) }
    }
  }).catch((err) => { console.log(err) })
}

export const getTechSupportRequests = (formData, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/getTechSupportRequests`, formData).then(res => {
    if (res.data.status == true) {
      dispatch(updateRedux({ key: 'tech_pending_data', value: res.data.request_details }))
      dispatch(updateRedux({ key: 'tech_pending_total_pages', value: res.data.pages }))
      dispatch(updateRedux({ key: 'tech_pending_page_status', value: res.data.page_status }))
      dispatch(updateRedux({ key: 'tech_pending_page', value: res.data.page }))
      dispatch(updateRedux({ key: 'tech_pending_path', value: res.data.request_path }))
      callback && callback()
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if (callback) { callback(res.data.status) }
    }
  }).catch((err) => { console.log(err) })
}

export const getTechSupportClosedRequests = (formData, callback) => (dispatch) => {
  console.log(formData)
  axiosFormInstance.post(`${API_BASE_URL}admin/getTechSupportClosedRequests`, formData).then(res => {
    if (res.data.status == true) {
      dispatch(updateRedux({ key: 'tech_closed_data', value: res.data.request_details }))
      dispatch(updateRedux({ key: 'tech_closed_total_pages', value: res.data.pages }))
      dispatch(updateRedux({ key: 'tech_closed_page_status', value: res.data.page_status }))
      dispatch(updateRedux({ key: 'tech_closed_page', value: res.data.page }))
      dispatch(updateRedux({ key: 'tech_closed_path', value: res.data.request_path }))
      callback && callback()
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if (callback) { callback(res.data.status) }
    }
  }).catch((err) => { console.log(err) })
}

export const deleteTechSupportRequest = (delId, callback) => (dispatch) => {
  const data = {
    id: delId
  };
  axiosInstance.post(`${API_BASE_URL}admin/deleteTechSupportRequest`, data).then((res) => {
    if (res.data.status) {
      if (callback) {
        callback()
      }
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getTechSupportRequestDetail = (id, callback) => (dispatch) => {
  axiosInstance.get(`${API_BASE_URL}admin/getTechSupportRequestDetail/${id}`).then((res) => {
    if (callback) {
      callback()
    }
    if (res.data.status) {
      dispatch(updateRedux({ key: 'tech_request_details', value: res.data.request_details }))
      dispatch(updateRedux({ key: 'tech_detail_path', value: res.data.request_path }))
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err.response, "6")
  })
}

export const updateTechSupportRequest = (formData, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/updateTechSupportRequest`, formData).then(res => {
    if (res.data.status == true) {
      callback && callback(res.data)
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if (callback) { callback(res.data.status) }
    }
  }).catch((err) => { console.log(err) })
}

export const getTechSupportRequestReport = (formData, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/getTechSupportRequestReport`, formData).then(res => {
    if (res.data.status == true) {
      dispatch(updateRedux({ key: 'tech_request_report_data', value: res.data.request_details }))
      dispatch(updateRedux({ key: 'tech_report_total_pages', value: res.data.pages }))
      dispatch(updateRedux({ key: 'tech_report_page_status', value: res.data.page_status }))
      dispatch(updateRedux({ key: 'tech_report_page', value: res.data.page }))
      dispatch(updateRedux({ key: 'tech_report_openCount', value: res.data.openCount }))
      dispatch(updateRedux({ key: 'tech_report_closedCount', value: res.data.closedCount }))
      if (callback) { callback(res.data.data) }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if (callback) { callback(res.data.status) }
    }
  }).catch((err) => { console.log(err) })
}

export const exportTechSupportRequestReport = (formData) => (dispatch) => {
  axios.post(`${API_BASE_URL}admin/exportTechSupportRequestReport`, formData, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'multipart/form-data',
      'Authorization': `Bearer ${localStorage.getItem("token")}`
    },
  }).then((res) => {
    dispatch(updateRedux({ key: "report_export_loader", value: false }));
    if (res.status == 200) {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute(
        'download',
        'tech_support_request_report.xlsx',
      );
      document.body.appendChild(link);
      link.click();
    } else {
      toast.error(res.data.message, {
        position: "bottom-center",
        autoClose: 3000
      })
    }
  }).catch((err) => {
    console.log(err)
  })
}

export const getTechSupportArchivedRequests = (formData, callback) => (dispatch) => {
  axiosFormInstance.post(`${API_BASE_URL}admin/getTechSupportArchivedRequests`, formData).then(res => {
    if (res.data.status == true) {
      dispatch(updateRedux({ key: 'tech_archived_list', value: res.data.request_details }))
      dispatch(updateRedux({ key: 'tech_archived_total_pages', value: res.data.pages }))
      dispatch(updateRedux({ key: 'tech_archived_page_status', value: res.data.page_status }))
      dispatch(updateRedux({ key: 'tech_archived_page', value: res.data.page }))
      if (callback) { callback(res.data.status) }
    } else {
      toast.error(res.data.message, { position: "bottom-center", autoClose: 3000 })
      if (callback) { callback(res.data.status) }
    }
  }).catch((err) => { console.log(err) })
}


