import React from "react";
import { HashRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./style.scss";
import { ToastContainer } from "react-toastify";

import ProtectedRoute from "./ProtectedRoute";
import Layout from "./components/Layout/Layout";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import CreateRequest from "./pages/ManageRequests/CreateRequest";
import ManageUserRoles from "./pages/ManageUserRoles/AddSignature";
import Reports from "./pages/Report/Reports";
import Layout_Main from "./components/Layout_Main/Layout_Main";
import Layout_pdf from "./components/Layout_pdf/Layout_pdf";
import PendingRequests from "./pages/ManageRequests/PendingRequests";
import RequestDetailsPage from "./pages/ManageRequests/RequestDetailsPage";
import AssignSignatory from "./components/AssignSignatory/AssignSignatory";

import { useSelector } from "react-redux";
import ClosedRequests from "./pages/ManageRequests/ClosedRequests";
import AssignRole from "./pages/ManageUserRoles/AssignRole";
import Testing from "./pages/testing/Testing";
import InProgress from "./pages/ManageRequests/InProgress";
import ScrollToTop from "./ScrollTop";
import AddRole from "./pages/ManageUserRoles/AddRole";
import EditRole from "./pages/ManageUserRoles/EditRole";
import { modulePermission } from "./utils/helpers";
import { permissionModules } from "./redux/commonReducer";
import ArchivedRequests from "./pages/ManageRequests/ArchivedRequests";
import NotFound from "./notFound";
import TechCreateRequest from "./pages/Techsupport/TechCreateRequest";
import TechPendingRequest from "./pages/Techsupport/TechPendingRequest";
import TechClosedRequest from "./pages/Techsupport/TechClosedRequest";
import TechRequestDetails from "./pages/Techsupport/TechRequestDetails";
import TechReport from "./pages/Report/TechReport";
import TechArchivedRequests from "./pages/Techsupport/TechArchivedRequests";

export default function RouteHandler() {
  // const wholeStore = useSelector((s) => console.log(s))
  const permission = useSelector(permissionModules);
  const is_tech_support = localStorage.getItem("is_tech_support")
  return (
    console.log(is_tech_support),
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route element={<ProtectedRoute />}>
        <Route element={<Layout />}>
          <Route element={<Layout_Main />}>
            <Route index element={<Dashboard />} />
            {modulePermission()?.includes("create_request") ? (
              <Route path="/" element={<Dashboard />} />
            ) : null}
            <Route
              path="request_details/:id"
              element={<RequestDetailsPage />}
            />
            <Route path="manage_requests">
              {modulePermission()?.includes("create_request") ? (
                <Route path="create_request" element={<CreateRequest />} />
              ) : null}
              <Route path="pending_requests">
                <Route index element={<PendingRequests />} />
                <Route
                  path="request_details/:id"
                  element={<RequestDetailsPage page={"pending"} />}
                />
              </Route>
              <Route path="closed_requests">
                <Route index element={<ClosedRequests />} />
                <Route
                  path="request_details/:id"
                  element={<RequestDetailsPage page={"closed"} />}
                />
              </Route>
              <Route path="inprogress_requests">
                <Route index element={<InProgress />} />
                <Route
                  path="request_details/:id"
                  element={<RequestDetailsPage page={"in-Progress"} />}
                />
              </Route>
              {modulePermission()?.includes("archives") ||
              modulePermission()?.includes("create_request") ? (
                <Route path="archived_requests">
                  <Route index element={<ArchivedRequests />} />
                  <Route
                    path="request_details/:id"
                    element={<RequestDetailsPage page={"archived"} />}
                  />
                </Route>
              ) : null}
              <Route path="assign_signatory" element={<AssignSignatory />} />
            </Route>
            <Route path="manage_user_roles">
              {modulePermission()?.includes("add_signature") ? (
                <Route path="add_signature" element={<ManageUserRoles />} />
              ) : null}
              {modulePermission()?.includes("assign_role") ? (
                <>
                  <Route path="assign_role" element={<AssignRole />} />
                  <Route path="add_role" element={<AddRole />} />
                  <Route path="edit_role/:id" element={<EditRole />} />
                </>
              ) : null}
            </Route>

            <Route path="tech_support">
              {(modulePermission()?.includes("techSupportUser")) ? (
                <Route path="create_request" element={<TechCreateRequest />} />
              ) : null}

              {/* {modulePermission()?.includes("techSupportPermission") ? (
                <> */}
              <Route path="pending_requests">
                <Route index element={<TechPendingRequest />} />
                <Route
                  path="request_details/:id"
                  element={<TechRequestDetails page={"pending"} />}
                />
              </Route>
              <Route path="closed_requests">
                <Route index element={<TechClosedRequest />} />
                <Route
                  path="request_details/:id"
                  element={<TechRequestDetails page={"closed"} />}
                />
              </Route>
              {modulePermission()?.includes("tech_supportArchives") || modulePermission()?.includes("techSupportUser") ? (
                <Route path="archived_requests">
                  <Route index element={<TechArchivedRequests />} />
                  <Route
                    path="request_details/:id"
                    element={<TechRequestDetails page={"archived"} />}
                  />
                </Route>
              ) : null}

              {/* </>
              ) : null} */}
            </Route>

            {modulePermission()?.includes("reports") ? (
              <>
                <Route path="reports" element={<Reports />} />
                <Route
                  path="reports/request_details/:id"
                  element={<RequestDetailsPage page={"Report-"} />}
                />
              </>
            ) : null}
            {modulePermission()?.includes("tech_supportReport") ? (
              <>
                <Route path="tech_support_reports" element={<TechReport />} />
                <Route
                  path="tech_support_reports/request_details/:id"
                  element={<TechRequestDetails page={"Report"} />}
                />
              </>
            ) : null}
          </Route>
          <Route
            path="/manage_requests/create_request/document/:id"
            element={<Layout_pdf />}
          />
          <Route
            path="/manage_requests/assign_signatory/document/:id"
            element={<Layout_pdf name={"assign_signatory"} />}
          />
        </Route>
      </Route>
      <Route path="/testing" element={<Testing />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}
