import React, { useEffect, useState } from "react";
import {
  getModules,
  getRoleDetails,
  updateUserRoles,
} from "../../redux/actionCreator";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Col, Form, Table } from "react-bootstrap";
import { editRoleDetails, modules } from "../../redux/commonReducer";
import { useDispatch, useSelector } from "react-redux";
// import Table from "../../components/Table/Table";
import {
  WORD_BACK,
  WORD_CANCEL,
  WORD_EDIT_ROLE,
  WORD_EDIT_USER_ROLE,
  WORD_EMAIL,
  WORD_EMPLOYEE_ID,
  WORD_MODIFY,
  WORD_NAME,
  WORD_PLEASE_SELECT_ROLE,
  WORD_RANK,
  WORD_SUBUNIT,
  WORD_UNIT,
  WORD_USERNAME,
  WORD_USER_ROLES_UPDATED_SUCCESFULLY,
} from "../../constants/translation";

export default function EditRole() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const roleDetails = useSelector(editRoleDetails);
  const moduleList = useSelector(modules);

  const [module, setModule] = useState([]);
  const [details, setDetails] = useState(null);

  useEffect(() => {
    dispatch(getModules());
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getRoleDetails(id));
    }
  }, [id]);

  useEffect(() => {
    if (roleDetails) {
      setDetails([
        {
          _id: roleDetails?._id,
          name: roleDetails?.name,
          email: roleDetails?.email,
          username: roleDetails?.username,
          unit: roleDetails?.unit[0].unit_name,
          subunit: roleDetails.subunit[0]?.subunit_name,
          rank : roleDetails?.rank[0]?.rank,
          employee_id : roleDetails?.employee_id
        },
      ]);
      setModule(roleDetails.permissions);
    }
  }, [roleDetails]);

  let Titles = [
    { title: WORD_NAME, key: "name" },
    { title: WORD_USERNAME, key: "username" },
    { title: WORD_EMAIL, key: "email" },
    { title: WORD_UNIT, key: "unit" },
    { title: WORD_SUBUNIT, key: "subunit" },
  ];

  const onCancelHandler = () => {
    navigate("/manage_user_roles/assign_role");
  };

  const editRoleHandler = () => {
    if (module?.length == 0) {
      toast.warning(WORD_PLEASE_SELECT_ROLE, {
        position: "bottom-center",
        autoClose: 3000,
      });
    } else {
      let formData = new FormData();
      module.forEach((item) => formData.append("modules[]", item));
      formData.append("users", details[0]._id);
      dispatch(
        updateUserRoles(formData, (status) => {
          if (status == true) {
            toast.success(WORD_USER_ROLES_UPDATED_SUCCESFULLY, {
              position: "bottom-center",
              autoClose: 3000,
            });
            navigate("/manage_user_roles/assign_role");
          }
        })
      );
    }
  };

  return (
    <div id="AssignRole">
      <h1 className="page-title">{WORD_EDIT_ROLE}</h1>
      <span className="button text-left">
        <button
          className="btn btn-secondary"
          onClick={() => navigate("/manage_user_roles/assign_role")}
        >
          {WORD_BACK}
        </button>
      </span>
      {/* <Table page={"edit role"} titles={Titles} data={details} /> */}
      <Table striped bordered hover className="mt-5">
        <thead>
          <tr>
            <th> {WORD_EMPLOYEE_ID}</th>
            <th> {WORD_RANK}</th>
            <th> {WORD_NAME}</th>
            <th> {WORD_UNIT}</th>
            <th> {WORD_SUBUNIT}</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{roleDetails?.employee_id}</td>
            <td>{roleDetails?.rank[0]?.rank}</td>
            <td>{roleDetails?.name}</td>
            <td>{roleDetails?.unit[0]?.unit_name}</td>
            <td>{roleDetails?.subunit[0]?.subunit_name}</td>
          </tr>
        </tbody>
      </Table>
      <div className="col-md-12">
        <div className="">
          <div className="d-flex justify-content-between align-items-center">
            <h6 className="m-0 form__title">{WORD_EDIT_USER_ROLE}</h6>
          </div>
          <hr />

          <div className="row flex-wrap">
            {moduleList?.map((ele, i) => {
              return (
                <div className="col-3 select__box mb-5">
                  <Form.Check
                    type="checkbox"
                    id={`${i}`}
                    checked={module.includes(ele.key) ? true : false}
                    onClick={(e) =>
                      e.target.checked
                        ? setModule([...module, ele.key])
                        : setModule(module.filter((item) => item != ele.key))
                    }
                  />
                  <Form.Label for={`${i}`}>{ele.Value_arabic}</Form.Label>
                </div>
              );
            })}
          </div>
          <div className="">
            <div className="d-flex gap-2">
              <div className="col-md-12 mt-4">
                <button
                  className="btn btn-primary ml-2"
                  onClick={() => editRoleHandler()}
                >
                  {WORD_MODIFY}
                </button>
                <Button
                  variant="btn btn-danger"
                  onClick={() => onCancelHandler()}
                >
                  {WORD_CANCEL}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
