import React, { Fragment, useEffect, useState } from "react";
import {
  WORD_ACTION_DETAILS,
  WORD_APPROVED_REQUEST,
  WORD_BACK,
  WORD_CLOSE,
  WORD_CLOSED_REQUEST,
  WORD_COMMENTS,
  WORD_COMMENT_HERE,
  WORD_COMMENT_REQUIRED,
  WORD_CREATED_DATE,
  WORD_DESCRIPTION,
  WORD_DETAILS,
  WORD_DOCUMENT,
  WORD_NAME,
  WORD_PENDING_REQUEST_DETAIL,
  WORD_REJECTED_REQUEST,
  WORD_REQUESTER,
  WORD_REQUEST_CLOSED_SUCCESSFULLY,
  WORD_REQUEST_FILE,
  WORD_REQUEST_ID,
  WORD_RETURNED_REQUEST,
  WORD_STATUS,
  WORD_SUBUNIT,
  WORD_SUPPORTING_DOCUMENTS,
  WORD_TECHSUPPORT_PENDING_REQUEST_DETAILS,
  WORD_TIME,
  WORD_TITLE,
  WORD_UNIT,
} from "../../constants/translation";
import PageSubTitle from "../../components/PageSubTitle/PageSubTitle";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getTechSupportRequestDetail,
  updateTechSupportRequest,
} from "../../redux/actionCreator";
import {
  tech_detail_loader,
  tech_detail_path,
  tech_request_details,
  updateRedux,
} from "../../redux/commonReducer";
import { API_BASE_URL } from "../../constants/configuration";
import { toast } from "react-toastify";
import { modulePermission } from "../../utils/helpers";

const TechRequestDetails = ({ page = "" }) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  const url = useSelector(tech_detail_path);
  const data = useSelector(tech_request_details);
  const full_loader = useSelector(tech_detail_loader);

  const [err, setErr] = useState({});
  const [comment, setComment] = useState("");
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    if (id) {
      dispatch(updateRedux({ key: "tech_detail_loader", value: true }));
      dispatch(
        getTechSupportRequestDetail(id, () => {
          dispatch(updateRedux({ key: "tech_detail_loader", value: false }));
        })
      );
    }
  }, [id]);

  const closeHandler = () => {
    if (comment?.trim()?.length) {
      let formData = new FormData();
      formData.append("id", id);
      formData.append("title", data.title?.trim());
      formData.append("description", data.description);
      formData.append("comment", comment);
      formData.append("currentStatus", "Closed");
      setLoader(true);
      dispatch(
        updateTechSupportRequest(formData, (res) => {
          if (res.status) {
            toast.success(WORD_REQUEST_CLOSED_SUCCESSFULLY, {
              position: "bottom-center",
              autoClose: 3000,
            });
            setComment("");
            setLoader(false);
            window.history.back();
          }
        })
      );
    } else {
      setErr({
        ...err,
        comment: WORD_COMMENT_REQUIRED,
      });
    }
  };

  return (
    console.log(page, "page"),
    (
      <>
        <div id="PendingRequestDetails">
          <h1 className="page-title">
            {WORD_TECHSUPPORT_PENDING_REQUEST_DETAILS}
          </h1>
          <div className="backButtonSection">
            <button className="back" onClick={() => window.history.back()}>
              {WORD_BACK}
            </button>
          </div>
          {full_loader ? (
            <div className="loader-div">
              <img
                className="circles-loader"
                src="/assets/loader/circles.svg"
                alt=""
              />
            </div>
          ) : (
            <>
              <section className="title">
                <PageSubTitle name={WORD_DETAILS} />
              </section>
              <section>
                <div id="RequestDetails">
                  <section className="status-details">
                    <span>
                      <p>{WORD_STATUS}</p>
                      <p
                        className={`status ${data?.currentStatus
                            ? data?.currentStatus?.toLowerCase()
                            : ""
                          }`}
                      >
                        {data?.currentStatus == "Pending"
                          ? WORD_PENDING_REQUEST_DETAIL
                          : data?.currentStatus == "Closed"
                            ? WORD_CLOSED_REQUEST
                            : ""}
                      </p>
                    </span>
                    <span>
                      <p>{WORD_REQUEST_ID}</p>
                      <p>{data?.reqId}</p>
                    </span>
                    <span>
                      <p>{WORD_REQUESTER}</p>
                      <p>{data?.created_by.name}</p>
                    </span>
                    <span>
                      <p>{WORD_CREATED_DATE}</p>
                      <p dir="ltr">
                        {moment(data?.createdAt).format("DD MMM yyyy")}
                      </p>
                    </span>
                  </section>
                  <hr />
                  <section className="description">
                    <span>
                      <p>{WORD_TITLE}</p>
                      <p>{data?.title}</p>
                    </span>
                    <span>
                      <p>{WORD_DESCRIPTION}</p>
                      <p>{data?.description}</p>
                    </span>
                  </section>

                  <section className="file">
                    {data?.attachment?.length > 0 && (
                      <>
                        <hr />
                        <section className="support-files">
                          <p className="key">{WORD_SUPPORTING_DOCUMENTS}</p>
                          <div className="files">
                            {data?.attachment?.map((item, i) => {
                              return (
                                <p className="value" key={i}>
                                  <a
                                    href={`${API_BASE_URL}${url}/${item}`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {item}{" "}
                                    <img src="./assets/icons/eye.svg" alt="" />
                                  </a>
                                </p>
                              );
                            })}
                          </div>
                        </section>
                      </>
                    )}
                  </section>

                  {page == "closed" || data?.currentStatus == "Closed" ? (
                    <>
                      <hr />
                      <div id="PageSubTitle">
                        <h2 className="title">{WORD_ACTION_DETAILS}</h2>
                      </div>
                      <section className="signatory-table table-responsive-sm">
                        <table>
                          <thead>
                            <tr>
                              <th style={{ width: "250px" }}>{WORD_NAME}</th>
                              {/* <th style={{ width: "140px" }}>{WORD_UNIT}</th>
    <th style={{ width: "150px" }}>{WORD_SUBUNIT}</th> */}
                              <th style={{ width: "220px" }}>{WORD_TIME}</th>
                              <th>{WORD_COMMENTS}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <Fragment key={"ai"}>
                              <tr>
                                <td colSpan="100%">
                                  <hr />
                                </td>
                              </tr>
                              <tr key={"i"}>
                                <td>{data?.currentId?.name}</td>
                                {/* <td>{data?.currentId?.unit[0].unit_name}</td>
      <td>{data?.currentId?.subunit[0].subunit_name}</td> */}
                                <td dir="ltr">
                                  {moment(data?.updated_at)
                                    .format("DD MMM yyyy-hh:mm A")
                                    .split("-")
                                    .map((o, pi) => (
                                      <p key={pi}>{o}</p>
                                    ))}
                                </td>
                                <td>
                                  <p>{data?.comment} </p>
                                </td>
                              </tr>
                            </Fragment>
                          </tbody>
                        </table>
                      </section>
                    </>
                  ) : (
                    <></>
                  )}
                  {modulePermission()?.includes("techSupportPermission") ? (
                    <>
                      {page == "pending" ? (
                        <>
                          <hr />
                          <section className="notes">
                            <p>{WORD_COMMENTS}</p>
                            <textarea
                              // maxLength="160"
                              onChange={(e) => {
                                setComment(e.target.value);
                                setErr({ ...err, comment: "" });
                              }}
                              className={err.comment ? "error" : ""}
                              name=""
                              id=""
                              cols="30"
                              rows="10"
                              value={comment}
                              placeholder={WORD_COMMENT_HERE}
                            ></textarea>
                            {err.comment && (
                              <p className="error-message">{err.comment}</p>
                            )}
                          </section>
                          <section className="buttons">
                            <button
                              className="Approve"
                              onClick={() => closeHandler()}
                              disabled={loader}
                            >
                              {loader && (
                                <img
                                  className="ms-4"
                                  src="assets/loader/loader-white.svg"
                                  alt=""
                                />
                              )}
                              {WORD_CLOSE}
                            </button>
                          </section>
                        </>
                      ) : (
                        <></>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </section>
            </>
          )}
        </div>
      </>
    )
  );
};
export default TechRequestDetails;
