import React from "react";
import Table from "../../components/Table/Table";
import PaginationRow from "../../components/PaginationRow/PaginationRow";
import { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Form } from "react-bootstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  exportReportData,
  getAllSubUnits,
  getAllUnits,
  getReport,
  getUser,
} from "../../redux/actionCreator";
import {
  report_approvedCount,
  report_closedCount,
  report_loader,
  report_openCount,
  report_pendingCount,
  report_rejectedCount,
  report_returnedCount,
  request_details,
  request_page_status,
  request_pages,
  request_total_pages,
  subunit_details,
  unit_details,
  updateRedux,
  userList,
} from "../../redux/commonReducer";
import ReactPaginate from "react-paginate";
import { toast } from "react-toastify";
import {
  WORD_ACTION,
  WORD_ALL,
  WORD_APPROVED,
  WORD_CANCEL,
  WORD_CREATED_BY,
  WORD_CREATED_DATE,
  WORD_CURRENT_STATUS,
  WORD_END_DATE,
  WORD_ENTER_KEYWORD,
  WORD_FROM,
  WORD_PENDING,
  WORD_PLEASE_ENTER_SEARCH,
  WORD_REJECTED,
  WORD_REPORTS,
  WORD_REQUEST_ID,
  WORD_RESPONSIBLE_USER,
  WORD_RETURNED,
  WORD_SEARCH,
  WORD_SECRETARY,
  WORD_SELECT_STATUS,
  WORD_SELECT_SUBUNIT,
  WORD_SELECT_UNIT,
  WORD_SELECT_USER,
  WORD_SIGNATORY,
  WORD_START_DATE,
  WORD_STATUS,
  WORD_SUBUNIT,
  WORD_SUPER_ADMIN,
  WORD_TITLE,
  WORD_TO,
  WORD_UNIT,
  WORD_USER,
  WORD_USER_TYPE,
} from "../../constants/translation";
import moment from "moment";
import Pagination from "../../components/CommonPagination/Pagination";

export default function Reports() {
  const dispatch = useDispatch();
  const userTypeInfo = localStorage.getItem("user_type");

  const unit_list = useSelector(unit_details);
  const sub_unit_list = useSelector(subunit_details);
  const user_list = useSelector(userList);
  const requestData = useSelector(request_details);
  const pageCount = useSelector(request_total_pages);
  const page_status = useSelector(request_page_status);
  const openCount = useSelector(report_openCount);
  const approvedCount = useSelector(report_approvedCount);
  const rejectedCount = useSelector(report_rejectedCount);
  const pendingCount = useSelector(report_pendingCount);
  const returnedCount = useSelector(report_returnedCount);
  const closedCount = useSelector(report_closedCount);
  const reportLoader = useSelector(report_loader);

  const [searchKey, setSearchKey] = useState("");
  const [limit, setLimit] = useState(10);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [unit, setUnit] = useState("");
  const [subUnit, setSubUnit] = useState("");
  const [user, setUser] = useState("");
  const [status, setStatus] = useState(null);
  const [userType, setUserType] = useState("");
  const [currentStatus, setCurrentStatus] = useState("");
  const [page, setPage] = useState(0);
  const [sortAscending, setSortAscending] = useState(false);
  const [sortColumn, setSortColumn] = useState("createdAt");
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);

  // console.log({ value: parse(status?.value), status })
  // console.log(Boolean('true'))

  let Titles = [
    { title: WORD_REQUEST_ID, key: "reqId", width: "150px", sort: "requestId" },
    {
      title: WORD_CREATED_BY,
      key: "createdBy",
      width: "180px",
      sort: "createdBy",
    },
    { title: WORD_TITLE, width: "200px", key: "title", sort: "title" },
    {
      title: WORD_CURRENT_STATUS,
      key: "c_status",
      width: "180px",
      sort: "currentStatus",
    },
    {
      title: WORD_RESPONSIBLE_USER,
      key: "currentId",
      width: "180px",
      sort: "currentId",
    },
    {
      title: WORD_CREATED_DATE,
      key: "createdAt",
      width: "170px",
      sort: "createdAt",
    },
    // { title: WORD_ACTION, key: "actions", width: "130px" },
  ];

  const current_status_list = [
    { value: "returned", label: WORD_RETURNED },
    { value: "rejected", label: WORD_REJECTED },
    { value: "pending", label: WORD_PENDING },
    { value: "Approved", label: WORD_APPROVED },
  ];

  const user_type_list = [
    { value: "all", label: WORD_ALL },
    { value: "signatory", label: WORD_SIGNATORY },
    { value: "secretery", label: WORD_SECRETARY },
    { value: "super_admin", label: WORD_SUPER_ADMIN },
  ];

  useEffect(() => {
    dispatch(getAllUnits());
    getDataHandler(0);
  }, []);

  useEffect(() => {
    setData([]);
    requestData?.forEach((item) => {
      setData((s) => [
        ...s,
        {
          reqId: item.reqId,
          title: item.title,
          c_status:
            item?.currentStatus == "pending"
              ? WORD_PENDING
              : item?.currentStatus == "returned"
                ? WORD_RETURNED
                : item?.currentStatus == "rejected"
                  ? WORD_REJECTED
                  : item?.currentStatus == "approved"
                    ? WORD_APPROVED
                    : "",
          currentId: item?.currentId[0]?.name,
          createdAt: item.createdAt,
          _id: item._id,
          createdBy: item?.created_by[0]?.name,
          currentStatus: item?.currentStatus,
        },
      ]);
    });
  }, [requestData]);

  const onUnitSelecthandler = (value) => {
    setUnit(value);
    setSubUnit("");
    setUser("");
    dispatch(updateRedux({ key: "subunit_details", value: [] }));
    dispatch(updateRedux({ key: "userList", value: [] }));
    if (value?._id?.length) {
      dispatch(getAllSubUnits(value._id));
    }
  };

  const onSubUnitSelecthandler = (value) => {
    setSubUnit(value);
    setUser("");
    dispatch(updateRedux({ key: "userList", value: [] }));
    if (value?._id?.length) {
      let formData = new FormData();
      formData.append("unit", unit._id);
      formData.append("subunit", value._id);
      dispatch(getUser(formData));
    }
  };

  const getDataHandler = (pag) => {
    console.log("333");
    let formData = new FormData();
    formData.append("start_date", "");
    formData.append("end_date", "");
    formData.append("user_type", "");
    formData.append("currentStatus", "");
    formData.append("unit", "");
    formData.append("subunit", "");
    formData.append("user", "");
    formData.append("is_active", false);
    formData.append("keyword", "");
    formData.append("perPage", limit);
    formData.append("page", pag);
    formData.append("sort_key", "createdAt");
    formData.append("sort_order", -1);
    dispatch(updateRedux({ key: "report_loader", value: true }));
    dispatch(
      getReport(formData, () => {
        dispatch(updateRedux({ key: "report_loader", value: false }));
      })
    );
  };

  const onSearchHandler = () => {
    if (
      startDate == "" &&
      endDate == "" &&
      userType == "" &&
      currentStatus == "" &&
      unit == "" &&
      subUnit == "" &&
      user == "" &&
      !status &&
      searchKey == ""
    ) {
      toast.warning(WORD_PLEASE_ENTER_SEARCH, {
        position: "bottom-center",
        autoClose: 3000,
      });
    } else {
      setLoader(true);
      setPage(0);
      setSortColumn("createdAt");
      setSortAscending(false);
      let formData = new FormData();
      if (startDate?.length != 0) {
        let s_date =
          moment(startDate).format("YYYY-MM-DD hh:mm:ss")?.split(" ")[0] +
          " 00:00:00";
        formData.append("start_date", s_date);
      } else {
        formData.append("start_date", "");
      }
      if (endDate?.length != 0) {
        let e_date =
          moment(endDate).format("YYYY-MM-DD hh:mm:ss")?.split(" ")[0] +
          " 23:59:59";
        formData.append("end_date", e_date);
      } else {
        formData.append("end_date", "");
      }
      formData.append("user_type", userType.value ?? "");
      formData.append("currentStatus", currentStatus.value ?? "");
      formData.append("unit", unit._id ?? "");
      formData.append("subunit", subUnit._id ?? "");
      formData.append("user", user._id ?? "");
      formData.append("is_active", status?.value ?? false);
      formData.append("keyword", searchKey);
      formData.append("perPage", limit);
      formData.append("page", 0);
      formData.append("sort_key", "createdAt");
      formData.append("sort_order", -1);
      console.log(formData, "12");
      dispatch(
        getReport(formData, () => {
          setLoader(false);
        })
      );
    }
  };

  const onCancelHandler = () => {
    setSearchKey("");
    setStartDate("");
    setEndDate("");
    setUnit("");
    setSubUnit("");
    setUser("");
    setUserType("");
    setStatus(null);
    setCurrentStatus("");
    setSortColumn("createdAt");
    setSortAscending(false);
    setPage(0);
    dispatch(updateRedux({ key: "subunit_details", value: [] }));
    dispatch(updateRedux({ key: "userList", value: [] }));
    getDataHandler(0);
  };

  const onExportHandler = () => {
    let formData = new FormData();
    if (startDate?.length != 0) {
      let s_date =
        moment(startDate).format("YYYY-MM-DD hh:mm:ss")?.split(" ")[0] +
        " 00:00:00";
      formData.append("start_date", s_date);
    } else {
      formData.append("start_date", "");
    }
    if (endDate?.length != 0) {
      let e_date =
        moment(endDate).format("YYYY-MM-DD hh:mm:ss")?.split(" ")[0] +
        " 23:59:59";
      formData.append("end_date", e_date);
    } else {
      formData.append("end_date", "");
    }
    formData.append("user_type", userType.value ?? "");
    formData.append("currentStatus", currentStatus.value ?? "");
    formData.append("unit", unit._id ?? "");
    formData.append("subunit", subUnit._id ?? "");
    formData.append("user", user._id ?? "");
    formData.append("is_active", status?.value ?? false);
    formData.append("keyword", searchKey);
    formData.append("perPage", limit);
    formData.append("page", page);
    formData.append("sort_key", sortColumn);
    formData.append("sort_order", sortAscending ? 1 : -1);
    dispatch(updateRedux({ key: "report_export_loader", value: true }));
    dispatch(exportReportData(formData));
  };

  const onLimitChangeHandler = (limitValue) => {
    if (limit != limitValue) {
      console.log("222", limitValue);
      setLimit(limitValue);
      setPage(0);
      let formData = new FormData();
      if (startDate?.length != 0) {
        let s_date =
          moment(startDate).format("YYYY-MM-DD hh:mm:ss")?.split(" ")[0] +
          " 00:00:00";
        formData.append("start_date", s_date);
      } else {
        formData.append("start_date", "");
      }
      if (endDate?.length != 0) {
        let e_date =
          moment(endDate).format("YYYY-MM-DD hh:mm:ss")?.split(" ")[0] +
          " 23:59:59";
        formData.append("end_date", e_date);
      } else {
        formData.append("end_date", "");
      }
      formData.append("user_type", userType.value ?? "");
      formData.append("currentStatus", currentStatus.value ?? "");
      formData.append("unit", unit._id ?? "");
      formData.append("subunit", subUnit._id ?? "");
      formData.append("user", user._id ?? "");
      formData.append("is_active", status?.value ?? false);
      formData.append("keyword", searchKey);
      formData.append("perPage", limitValue ? limitValue : limit);
      formData.append("page", 0);
      formData.append("sort_key", sortColumn);
      formData.append("sort_order", sortAscending ? 1 : -1);
      // console.log(formData, "12");
      dispatch(getReport(formData));
    }
  };

  const handlePageChange = (e) => {
    setPage(e.selected);
    if (e.selected >= 0) {
      console.log("111");
      let formData = new FormData();
      if (startDate?.length != 0) {
        let s_date =
          moment(startDate).format("YYYY-MM-DD hh:mm:ss")?.split(" ")[0] +
          " 00:00:00";
        formData.append("start_date", s_date);
      } else {
        formData.append("start_date", "");
      }
      if (endDate?.length != 0) {
        let e_date =
          moment(endDate).format("YYYY-MM-DD hh:mm:ss")?.split(" ")[0] +
          " 23:59:59";
        formData.append("end_date", e_date);
      } else {
        formData.append("end_date", "");
      }
      formData.append("user_type", userType.value ?? "");
      formData.append("currentStatus", currentStatus.value ?? "");
      formData.append("unit", unit._id ?? "");
      formData.append("subunit", subUnit._id ?? "");
      formData.append("user", user._id ?? "");
      formData.append("is_active", status?.value ?? false);
      formData.append("keyword", searchKey);
      formData.append("perPage", limit);
      formData.append("page", e.selected);
      formData.append("sort_key", sortColumn);
      formData.append("sort_order", sortAscending ? 1 : -1);
      dispatch(getReport(formData));
    }
  };

  const onSortHandler = (type) => {
    setSortAscending(!sortAscending);
    setSortColumn(type);
    let formData = new FormData();
    if (startDate?.length != 0) {
      let s_date =
        moment(startDate).format("YYYY-MM-DD hh:mm:ss")?.split(" ")[0] +
        " 00:00:00";
      formData.append("start_date", s_date);
    } else {
      formData.append("start_date", "");
    }
    if (endDate?.length != 0) {
      let e_date =
        moment(endDate).format("YYYY-MM-DD hh:mm:ss")?.split(" ")[0] +
        " 23:59:59";
      formData.append("end_date", e_date);
    } else {
      formData.append("end_date", "");
    }
    formData.append("user_type", userType.value ?? "");
    formData.append("currentStatus", currentStatus.value ?? "");
    formData.append("unit", unit._id ?? "");
    formData.append("subunit", subUnit._id ?? "");
    formData.append("is_active", status?.value ?? false);
    formData.append("user", user._id ?? "");
    formData.append("keyword", searchKey);
    formData.append("perPage", limit);
    formData.append("page", page);
    formData.append("sort_key", type);
    formData.append("sort_order", !sortAscending ? 1 : -1);
    dispatch(getReport(formData));
  };

  return (
    <div id="report-page">
      <div className="status-grid mb-4">
        <div className="row justify-content-start">
          <div className="col-md-2 col-tab-3">
            <h1 className="page-title mr-0">{WORD_REPORTS}</h1>
            <div className="list">
              <h2>{WORD_APPROVED}</h2>
              <h4>{approvedCount}</h4>
            </div>
          </div>
          <div className="col-md-2 col-tab-3">
            <div className="list pending">
              <h2>{WORD_PENDING}</h2>
              <h4 style={{ color: "#ffc107" }}>{pendingCount}</h4>
            </div>
          </div>
          <div className="col-md-2 col-tab-3">
            <div className="list pending">
              <h2>{WORD_REJECTED}</h2>
              <h4 style={{ color: "#e95030" }}>{rejectedCount}</h4>
            </div>
          </div>
          <div className="col-md-2 col-tab-3">
            <div className="list pending">
              <h2>{WORD_RETURNED}</h2>
              <h4 style={{ color: "#ffa500" }}>{returnedCount}</h4>
            </div>
          </div>
          {/* <div className="col-md-2 col-tab-3">
            <div className="list closed">
              <h2>Closed</h2>
              <h4 style={{ color: "#000" }}>{closedCount}</h4>
            </div>
          </div> */}
        </div>
      </div>
      <div className="searchBar">
        <div className="container">
          <div className="row">
            <div className="col-md-3 pl-0">
              <div className="form-group">
                <div className="row">
                  <div className="col-md-6 pl-0">
                    <label htmlFor="">{WORD_FROM}</label>
                    <DatePicker
                      className="w-100"
                      showIcon
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      placeholderText={WORD_START_DATE}
                      maxDate={new Date()}
                    />
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="">{WORD_TO}</label>
                    <DatePicker
                      className="w-100"
                      showIcon
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      placeholderText={WORD_END_DATE}
                      minDate={startDate}
                      maxDate={new Date()}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="col pl-0 ">
              <div className="form-group">
                <label htmlFor="">{WORD_CURRENT_STATUS}</label>
                <Select
                  options={current_status_list}
                  value={currentStatus}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  className="basic-select"
                  placeholder={WORD_SELECT_STATUS}
                  classNamePrefix="select"
                  onChange={(e) => setCurrentStatus(e)}
                />
              </div>
            </div>
            {/* <div className="col pl-0 ">
              <div className="form-group">
                <label htmlFor="">{WORD_USER_TYPE}</label>
                <Select
                  options={user_type_list}
                  value={userType}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  className="basic-select"
                  placeholder="Select user type"
                  classNamePrefix="select"
                  onChange={(e) => setUserType(e)}
                />
              </div>
            </div> */}
            {userTypeInfo !== "super_admin" ? (
              <></>
            ) : (
              <>
                <div className="col pl-0 ">
                  <div className="form-group">
                    <label htmlFor="">{WORD_UNIT}</label>
                    <Select
                      options={unit_list}
                      value={unit}
                      getOptionLabel={(option) => option.unit_name}
                      getOptionValue={(option) => option._id}
                      className="basic-select"
                      placeholder={WORD_SELECT_UNIT}
                      classNamePrefix="select"
                      onChange={(e) => onUnitSelecthandler(e)}
                    />
                  </div>
                </div>
                <div className="col pl-0 ">
                  <div className="form-group">
                    <label htmlFor="">{WORD_SUBUNIT}</label>
                    <Select
                      options={sub_unit_list}
                      value={subUnit}
                      getOptionLabel={(option) => option.subunit_name}
                      getOptionValue={(option) => option._id}
                      className="basic-select"
                      placeholder={WORD_SELECT_SUBUNIT}
                      classNamePrefix="select"
                      onChange={(e) => onSubUnitSelecthandler(e)}
                      isDisabled={sub_unit_list?.length == 0 ? true : false}
                    />
                  </div>
                </div>
                <div className="col ">
                  <div className="form-group">
                    <label htmlFor="">{WORD_USER}</label>
                    <Select
                      options={user_list}
                      value={user}
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option._id}
                      className="basic-select"
                      placeholder={WORD_SELECT_USER}
                      classNamePrefix="select"
                      onChange={(e) => setUser(e)}
                      isDisabled={user_list?.length == 0 ? true : false}
                    />
                  </div>
                </div>
              </>
            )}

            <div className="col ">
              <div className="form-group">
                <label htmlFor="">{WORD_STATUS}</label>
                <Select
                  options={[
                    { label: "Active", value: false },
                    { label: "Deleted", value: true },
                    { label: "All", value: "all" },
                  ]}
                  value={status}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  className="basic-select"
                  placeholder={WORD_SELECT_STATUS}
                  classNamePrefix="select"
                  onChange={(e) => setStatus(e)}
                />
              </div>
            </div>
          </div>
          <div className="row justify-content-between">
            <div className="col-md-5">
              <div className="form-group position-relative">
                <input
                  type="text"
                  placeholder={WORD_ENTER_KEYWORD}
                  className="form-control"
                  value={searchKey}
                  onChange={(e) => setSearchKey(e.target.value)}
                />
                <img
                  src="./assets/icons/search.svg"
                  className="search-icon"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-4 d-flex gap-2">
              <button
                className="btn btn-primary w-50"
                onClick={() => onSearchHandler()}
                disabled={loader}
              >
                {WORD_SEARCH}
                {loader && (
                  <img
                    className="me-4"
                    src="assets/loader/loader-white.svg"
                    alt=""
                  />
                )}
              </button>
              <button
                className="btn btn-danger w-50"
                onClick={() => onCancelHandler()}
                disabled={loader}
              >
                {WORD_CANCEL}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div id="Reports" className="report-sec">
        <section>
          <PaginationRow
            page={"reports"}
            exportData={() => onExportHandler()}
            onCount={(count) => onLimitChangeHandler(count)}
          />
        </section>
        <section className="mt-4">
          {reportLoader ? (
            <div className="loader-div">
              <img
                className="circles-loader"
                src="/assets/loader/circles.svg"
                alt=""
              />
            </div>
          ) : (
            <>
              <Table
                page={"reports"}
                titles={Titles}
                data={data}
                onSortHandler={onSortHandler}
                sortColumn={sortColumn}
                sortAscending={sortAscending}
              />
              <div className="align-between">
                <p style={{ direction: "ltr", color: "#0d7b58" }}>
                  {page_status}
                </p>
                <Pagination
                  page={page}
                  pageCount={pageCount}
                  handlePageChange={handlePageChange}
                />
              </div>
            </>
          )}
        </section>
      </div>
    </div>
  );
}
