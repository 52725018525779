import React, { useEffect, useState } from "react";
import Table from "../../components/Table/Table";
import PaginationRow from "../../components/PaginationRow/PaginationRow";
import { useDispatch, useSelector } from "react-redux";
import { getArchivedRequests } from "../../redux/actionCreator";
import {
  archived_list,
  archived_loader,
  archived_page_status,
  archived_total_pages,
  updateRedux,
} from "../../redux/commonReducer";
import {
  WORD_ACTION,
  WORD_CREATED_DATE,
  WORD_REQUESTER,
  WORD_REQUEST_ID,
  WORD_TITLE,
  WORD_SELECT_RANK,
  WORD_SELECT_UNIT,
  WORD_SELECT_SUBUNIT,
  WORD_ARCHIVED_REQUEST,
  WORD_CURRENT_STATUS,
  WORD_SENT_TO,
  WORD_APPROVED_BY,
  WORD_RETURNED_TO,
  WORD_REJECTED_BY,
  WORD_STATUS,
  WORD_DELETED_BY,
  WORD_DELETED_AT,
  WORD_STATUS_DELETE,
  WORD_DELETED_DATE,
} from "../../constants/translation";
import Pagination from "../../components/CommonPagination/Pagination";
import CommonSearch from "../../components/FormSearch/CommonSearch";
import { useLocation, useNavigate } from "react-router";
import moment from "moment";

export default function ArchivedRequests() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  let userId = JSON.parse(localStorage.getItem("user"))?._id;

  const archived_data = useSelector(archived_list);
  const pageCount = useSelector(archived_total_pages);
  const page_status = useSelector(archived_page_status);
  const loader = useSelector(archived_loader);

  const [modalRequestshow, setModalRequestShow] = useState(false);
  const [delId, setDelId] = useState("");
  const handleClose = () => setModalRequestShow(false);

  let Titles = [
    { title: WORD_REQUEST_ID, key: "reqId", width: "150px", sort: "requestId" },
    { title: WORD_TITLE, key: "title", sort: "title" },
    {
      title: WORD_REQUESTER,
      key: "created_by",
      width: "180px",
      sort: "createdBy",
    },
    {
      title: WORD_STATUS_DELETE,
      key: "status",
      width: "180px",
      sort: "currentStatus",
    },
    {
      title: WORD_CREATED_DATE,
      key: "createdAt",
      width: "150px",
      sort: "createdAt",
    },
    {
      title: WORD_DELETED_BY,
      key: "deleted_by",
      width: "150px",
      sort: "deleted_by",
    },
    {
      title: WORD_DELETED_DATE,
      key: "deleted_at",
      width: "150px",
      sort: "deleted_at",
    },
    { title: WORD_ACTION, key: "handler", width: "130px" },
  ];

  const [data, setData] = useState([]);
  const [searchKey, setSearchKey] = useState("");
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [rank, setRank] = useState({ rank: WORD_SELECT_RANK });
  const [unit, setUnit] = useState({ unit_name: WORD_SELECT_UNIT });
  const [subUnit, setSubUnit] = useState({ subunit_name: WORD_SELECT_SUBUNIT });
  const [archivedStatus, setArchivedStatus] = useState("");
  const [sortAscending, setSortAscending] = useState(false);
  const [sortColumn, setSortColumn] = useState("deleted_at");
  const [self, setSelf] = useState("");

  function setUpStatus(details) {
    // console.log({ details });
    let string;
    if (details?.currentStatus === "pending") {
      string = `${WORD_SENT_TO} : `;
    }
    if (details?.currentStatus === "approved") {
      string = `${WORD_APPROVED_BY} : `;
    }
    if (details?.currentStatus === "returned") {
      string = `${WORD_RETURNED_TO} : `;
    }
    if (details?.currentStatus === "rejected") {
      string = `${WORD_REJECTED_BY} : `;
    }
    string +=
      details?.currentId?.name +
      "\n" +
      moment(details?.updatedAt).format("DD MMM YYYY");
    return <pre dir="ltr">{string}</pre>;
  }

  useEffect(() => {
    let formData = new FormData();
    formData.append("unit", "");
    formData.append("subunit", "");
    formData.append("rank", "");
    formData.append("status", "");
    formData.append("page", 0);
    formData.append("perPage", limit);
    formData.append("keyword", searchKey);
    formData.append("sort_key", sortColumn);
    formData.append("sort_order", sortAscending ? 1 : -1);
    dispatch(updateRedux({ key: "archived_loader", value: true }));
    dispatch(
      getArchivedRequests(formData, () => {
        dispatch(updateRedux({ key: "archived_loader", value: false }));
      })
    );
  }, []);

  useEffect(() => {
    setData([]);
    if (archived_data) {
      archived_data?.forEach((item) => {
        setData((s) => [
          ...s,
          {
            reqId: item.reqId,
            title: item.title,
            createdAt: item.createdAt,
            _id: item._id,
            created_by: item?.created_by,
            currentStatus: item?.currentStatus,
            status: setUpStatus(item),
            deleted_at: item.deleted_at,
            deleted_by: item?.deleted_by,
            handler: (
              <div className="d-flex align-items-center justify-content-right">
                <img
                  className="eye mt-3"
                  src="./assets/icons/eye.svg"
                  alt="view"
                  onClick={() => navigate(`request_details/${item?._id}`)}
                />
              </div>
            ),
          },
        ]);
      });
    }
  }, [archived_data]);

  useEffect(() => {
    localStorage.setItem("page_url", location.pathname);
  }, []);

  const onLimitChangeHandler = (limitValue) => {
    if (limit != limitValue) {
      setLimit(limitValue);
      setPage(0);
      let formData = new FormData();
      formData.append("unit", unit?._id ?? "");
      formData.append("subunit", subUnit?._id ?? "");
      formData.append("rank", rank?._id ?? "");
      formData.append("status", archivedStatus?.value ?? "");
      formData.append("page", 0);
      formData.append("perPage", limitValue);
      formData.append("keyword", searchKey);
      formData.append("sort_key", sortColumn);
      formData.append("sort_order", sortAscending ? 1 : -1);
      dispatch(getArchivedRequests(formData));
    }
  };

  const handlePageChange = (e) => {
    setPage(e.selected);
    if (e.selected >= 0) {
      let formData = new FormData();
      formData.append("unit", unit?._id ?? "");
      formData.append("subunit", subUnit?._id ?? "");
      formData.append("rank", rank?._id ?? "");
      formData.append("status", archivedStatus?.value ?? "");
      formData.append("page", e.selected);
      formData.append("perPage", limit);
      formData.append("keyword", searchKey);
      formData.append("sort_key", sortColumn);
      formData.append("sort_order", sortAscending ? 1 : -1);
      dispatch(getArchivedRequests(formData));
    }
  };

  const onSortHandler = (type) => {
    setSortAscending(!sortAscending);
    setSortColumn(type);
    let formData = new FormData();
    formData.append("unit", unit?._id ?? "");
    formData.append("subunit", subUnit?._id ?? "");
    formData.append("rank", rank?._id ?? "");
    formData.append("status", archivedStatus?.value ?? "");
    formData.append("page", page);
    formData.append("perPage", limit);
    formData.append("keyword", searchKey);
    formData.append("sort_key", type);
    formData.append("sort_order", !sortAscending ? 1 : -1);
    dispatch(getArchivedRequests(formData));
  };

  const deleteRequestCurrent = (id) => {
    setDelId(id);
    setModalRequestShow(true);
  };

  function searchHandler(value) {
    setPage(0);
    let formData = new FormData();
    formData.append("unit", unit?._id ?? "");
    formData.append("subunit", subUnit?._id ?? "");
    formData.append("rank", rank?._id ?? "");
    formData.append("page", 0);
    formData.append("perPage", limit);
    formData.append("keyword", searchKey);
    formData.append("sort_key", sortColumn);
    formData.append("sort_order", sortAscending ? 1 : -1);
    dispatch(getArchivedRequests(formData));
  }

  return (
    <div id="PendingRequests">
      <h1 className="page-title">{WORD_ARCHIVED_REQUEST}</h1>
      <section>
        <CommonSearch
          pageName="archived"
          page={page}
          setPage={setPage}
          limit={limit}
          getData={getArchivedRequests}
          rank={rank}
          setRank={setRank}
          unit={unit}
          setUnit={setUnit}
          subUnit={subUnit}
          setSubUnit={setSubUnit}
          searchKey={searchKey}
          setSearchKey={setSearchKey}
          archivedStatus={archivedStatus}
          setArchivedStatus={setArchivedStatus}
          setSortAscending={setSortAscending}
          setSortColumn={setSortColumn}
          self={self}
          setSelf={setSelf}
        />
      </section>
      <section>
        <PaginationRow
          // onSearch={searchHandler}
          // setSearchKey = {setSearchKey}
          page={"archived"}
          onCount={(count) => onLimitChangeHandler(count)}
        />
      </section>
      <section>
        {loader ? (
          <div className="loader-div">
            <img
              className="circles-loader"
              src="/assets/loader/circles.svg"
              alt=""
            />
          </div>
        ) : (
          <>
            {" "}
            <Table
              page="archived"
              titles={Titles}
              data={data}
              onSortHandler={onSortHandler}
              sortColumn={sortColumn}
              sortAscending={sortAscending}
            />
            <div className="align-between">
              <p style={{ direction: "ltr", color: "#0d7b58" }}>
                {page_status}
              </p>
              <Pagination
                page={page}
                pageCount={pageCount}
                handlePageChange={handlePageChange}
              />
            </div>
          </>
        )}
      </section>
    </div>
  );
}
