import React from "react";
import "./Header_Pdf_Layout.scss";
import { SignatureButton, pageChangeHandler } from "../PDFviewer/PDFviewer";
import { WORD_OF, WORD_SELECT_SIGNATORY } from "../../constants/translation";

export default function Header_Pdf_Layout({ pages, signatories }) {
  // console.log([...signatories]?.sort((a, b) => a.order - b.order).map(signatory => signatory))

  // console.log(signatories)

  return (
    <div id="Header_Pdf_Layout">
      <span className="signatory-selection">
        <p>{WORD_SELECT_SIGNATORY}</p>
        <div>
          {signatories &&
            signatories
              .filter((item) => item.status === "pending")
              .filter((item) => !item.is_reviewer)
              .sort((a, b) => a.order - b.order)
              .map((item, i) => {
                return (
                  <SignatureButton
                    key={i}
                    name={item.signatory.name}
                    page={pages?.current}
                    sl_no={i + 1}
                    signatory_id={item?.signatory}
                  />
                );
              })}
        </div>
      </span>
      <span className="pagination">
        <img
          // onClick={() => pageChangeHandler(1)}
          onClick={() => pageChangeHandler(-1)}
          className="right"
          src="./assets/icons/arrow.svg"
          alt=""
        />
        <p dir="ltr">
          <span dir="rtl">{pages?.current} {WORD_OF} {pages?.totalPages}</span>
        </p>
        <img
          // onClick={() => pageChangeHandler(-1)}
          onClick={() => pageChangeHandler(1)}
          className="left"
          src="./assets/icons/arrow.svg"
          alt=""
        />
      </span>
    </div>
  );
}
