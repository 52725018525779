import React from 'react'

export default function Testing() {

    const data = {
        "signatory": [
            {
                "name": "signatory 1",
                "status": "approved",
                "level": 1
            },
            {
                "name": "signatory 2",
                "status": "returned",
                "level": 2
            }
        ],
        "secretery": [
            {
                "name": "secretery 1",
                "status": "approved",
                "level": 1,
                "comment": "comment 1"
            },
            {
                "name": "secretery 1",
                "status": "pending",
                "level": 1,
                "comment": "comment 2"
            }
        ]
    }



    const actions = [
        null,
        [
            {
                "signatory": {
                    "workshift": [],
                    "_id": "6446221df08ea7e20a8b3fb5",
                    "email": "emily@smarthatch.com",
                    "isadmin": "0",
                    "name": "Emily",
                    "password": "$2b$08$LP0xxwT6cLfhmPV/u/EWe.G/zkWvUn861B/xy8i9irwpZUmqI4YKu",
                    "role": "admin",
                    "username": "emily",
                    "user_status": "1",
                    "is_deleted": "0",
                    "user_type": "admin",
                    "subunit": [
                        {
                            "_id": "64466cfab424c79c18687403",
                            "subunit_name": "Finance Sub 2"
                        }
                    ],
                    "unit": [
                        {
                            "_id": "64466bf3b424c79c18687400",
                            "unit_name": "Finance"
                        }
                    ],
                    "is_secretery": false,
                    "signature": "1682489989364_pngwing.com (7).png",
                    "updatedAt": "2023-04-26T06:19:49.368Z",
                    "permissions": []
                },
                "status": "approved",
                "created_by": "644620d6f08ea7e20a8b3fb2",
                "level": 1,
                "order": 1,
                "flag": 0,
                "comment": "asdfasfasdf",
                "comment_allowed": false,
                "priority": false,
                "type": "signatory",
                "_id": "64494384efbb1558c0411cdb",
                "date": "2023-04-26T15:30:12.719Z"
            },
            {
                "secretery": {
                    "workshift": [],
                    "_id": "64462268f08ea7e20a8b3fb6",
                    "email": "bimal@smarthatch.com",
                    "isadmin": "0",
                    "name": "Bimal",
                    "password": "$2b$08$2q3KzYV0OgD5DE1X7Rffk.6PVcucpjKA2SUh0/.Mc20htXj/XsMHm",
                    "role": "admin",
                    "username": "bimal",
                    "user_status": "1",
                    "is_deleted": "0",
                    "user_type": "admin",
                    "subunit": [
                        {
                            "_id": "64466d3fb424c79c18687405",
                            "subunit_name": "HR Sub 2"
                        }
                    ],
                    "unit": [
                        {
                            "_id": "64466b7bb424c79c186873ff",
                            "unit_name": "HR"
                        }
                    ],
                    "is_secretery": true,
                    "updatedAt": "2023-04-26T06:19:39.061Z",
                    "signature": "1682489979061_pngwing.com (6).png",
                    "permissions": []
                },
                "level": 1,
                "created_by": "644620d6f08ea7e20a8b3fb2",
                "status": "approved",
                "priority": false,
                "type": "secretery",
                "_id": "64494384efbb1558c0411cdd",
                "date": "2023-04-26T15:30:12.721Z",
                "comment": "bimal note before creating second level signatories"
            },
            {
                "signatory": {
                    "workshift": [],
                    "_id": "644621aaf08ea7e20a8b3fb4",
                    "email": "faisal@smarthatch.com",
                    "isadmin": "0",
                    "name": "Faisal",
                    "password": "$2b$08$P49Dl5B/n/UQ8CwK5Vo0sOJnE6urXCRIt4lT7DFJY42jksf743niO",
                    "role": "admin",
                    "username": "faisal",
                    "user_status": "1",
                    "is_deleted": "0",
                    "user_type": "admin",
                    "subunit": [
                        {
                            "_id": "64466d72b424c79c18687407",
                            "subunit_name": "Procurement Sub 2"
                        }
                    ],
                    "unit": [
                        {
                            "_id": "64466c04b424c79c18687401",
                            "unit_name": "Procurement"
                        }
                    ],
                    "is_secretery": false,
                    "updatedAt": "2023-04-26T06:20:00.525Z",
                    "signature": "1682490000524_pngwing.com (8).png",
                    "permissions": []
                },
                "status": "returned",
                "created_by": "64462268f08ea7e20a8b3fb6",
                "level": 1,
                "order": 1,
                "flag": 0,
                "comment": "faisal return from level 2 to bimal the secretary ",
                "comment_allowed": false,
                "priority": false,
                "type": "signatory",
                "_id": "6449442aefbb1558c0411df0",
                "date": "2023-04-26T15:32:58.442Z"
            },
            {
                "secretery": {
                    "workshift": [],
                    "_id": "64462268f08ea7e20a8b3fb6",
                    "email": "bimal@smarthatch.com",
                    "isadmin": "0",
                    "name": "Bimal",
                    "password": "$2b$08$2q3KzYV0OgD5DE1X7Rffk.6PVcucpjKA2SUh0/.Mc20htXj/XsMHm",
                    "role": "admin",
                    "username": "bimal",
                    "user_status": "1",
                    "is_deleted": "0",
                    "user_type": "admin",
                    "subunit": [
                        {
                            "_id": "64466d3fb424c79c18687405",
                            "subunit_name": "HR Sub 2"
                        }
                    ],
                    "unit": [
                        {
                            "_id": "64466b7bb424c79c186873ff",
                            "unit_name": "HR"
                        }
                    ],
                    "is_secretery": true,
                    "updatedAt": "2023-04-26T06:19:39.061Z",
                    "signature": "1682489979061_pngwing.com (6).png",
                    "permissions": []
                },
                "level": 1,
                "status": "approved",
                "priority": false,
                "_id": "6449447befbb1558c0411e62",
                "date": "2023-04-26T15:34:19.699Z",
                "comment": "notes by bimal after returned from faisal on level 2"
            }
        ],
        [
            {
                "signatory": {
                    "workshift": [],
                    "_id": "6447953592f223b59a4223f7",
                    "email": "anchitha@smarthtach.com",
                    "isadmin": "0",
                    "name": "Anchitha",
                    "password": "$2b$08$hQC948CpkupP.vyztYYC/OlTTt4pGNQ73ktWummMHHQPaIbBwgoa6",
                    "role": "admin",
                    "username": "anchitha",
                    "user_status": "1",
                    "is_deleted": "0",
                    "user_type": "admin",
                    "is_secretery": false,
                    "manageRole": "secretery",
                    "subunit": [
                        {
                            "_id": "64466d3fb424c79c18687405",
                            "subunit_name": "HR Sub 2"
                        }
                    ],
                    "unit": [
                        {
                            "_id": "64466b7bb424c79c186873ff",
                            "unit_name": "HR"
                        }
                    ],
                    "signature": "1682491547776_pngwing.com (10).png",
                    "updatedAt": "2023-04-26T06:45:47.776Z",
                    "permissions": []
                },
                "status": "pending",
                "created_by": "64462268f08ea7e20a8b3fb6",
                "level": 2,
                "order": 1,
                "flag": 0,
                "comment": "",
                "comment_allowed": false,
                "priority": true,
                "type": "signatory",
                "_id": "6449467eefbb1558c0411f28",
                "date": "2023-04-26T15:42:54.174Z"
            },
            {
                "secretery": {
                    "workshift": [],
                    "user_type": "admin",
                    "_id": "64462151f08ea7e20a8b3fb3",
                    "email": "akash@smarthatch.com",
                    "isadmin": "0",
                    "name": "Akash",
                    "password": "$2b$08$TE8ab0AZeP2mRYy8ZjDjnuT6oOCu9F2jwN7saDfEh9wfSLAkIUM7C",
                    "role": "admin",
                    "username": "akash",
                    "user_status": "1",
                    "is_deleted": "0",
                    "subunit": [
                        {
                            "_id": "64466ceeb424c79c18687402",
                            "subunit_name": "Finance Sub 1"
                        }
                    ],
                    "unit": [
                        {
                            "_id": "64466bf3b424c79c18687400",
                            "unit_name": "Finance"
                        }
                    ],
                    "is_secretery": true,
                    "updatedAt": "2023-04-26T06:20:11.532Z",
                    "signature": "1682490011532_pngwing.com (9).png",
                    "permissions": []
                },
                "level": 2,
                "created_by": "64462268f08ea7e20a8b3fb6",
                "status": "pending",
                "priority": false,
                "type": "secretery",
                "comment": "",
                "_id": "6449467eefbb1558c0411f29",
                "date": "2023-04-26T15:42:54.175Z"
            }
        ]
    ]




    return (
        <div>
            <table>
                <thead>
                    <tr>
                        <th style={{ width: '150px' }}>Name</th>
                        <th style={{ width: '150px' }}>Unit</th>
                        <th style={{ width: '150px' }}>Subunit</th>
                        <th style={{ width: '150px' }}>Status</th>
                        <th style={{ width: '150px' }}>Approval Time</th>
                        <th style={{ width: '250px' }}>Comment</th>
                    </tr>
                </thead>
                <tbody>

                    {
                        actions[1].map((elem, i) => {
                            if (elem.type === 'signatory') {
                                return (
                                    <tr key={i}>
                                        <td>{elem.signatory.name}</td>
                                        <td>{elem.signatory.unit[0].unit_name}</td>
                                        <td>{elem.signatory.subunit[0].subunit_name}</td>
                                        <td>
                                            {/* <span className={'status ' + (elem.status)}>
                                                {capitalize(elem.status)}
                                            </span> */}
                                            {elem.status}
                                        </td>
                                        <td >{elem.updatedAt}</td>
                                        <td>
                                            <p>{elem.comment}</p>
                                        </td>
                                    </tr >
                                )
                            } else {
                                return (
                                    <tr>
                                        <td className='key'>Secretary Note <br /> - {elem.secretery.name}</td>
                                        <td colSpan='100%'>
                                            {elem.comment}
                                        </td>
                                    </tr>
                                )
                            }
                        })
                    }
                    <tr>
                        <td></td>
                        <td></td>
                        <td></td>
                    </tr>
                </tbody>
            </table>

        </div>
    )
}
