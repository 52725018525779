import React from 'react'
import './Layout_Main.scss'
import { Outlet } from 'react-router-dom'

export default function Layout_Main() {
    return (
        <div id='Layout_Main'>
            <div className='main-platform'>
                <Outlet />
            </div>
        </div>
    )
}
