// export const WORD_SIGN_INTO = "الدخول إلى نظام التوقيع الالكتروني";
// export const WORD_USERNAME = "اسم المستخدم";
// export const WORD_PASSWORD = "كلمة السر";
// export const WORD_SIGN_IN = "	تسجيل دخول";
// export const WORD_USERNAME_NOT = "	اسم المستخدم غير صحيح";
// export const WORD_LOGGED_SUCCESS = "تم تسجيل الدخول بنجاح";
// export const WORD_ENTER_UNAME = "أدخل اسم المستخدم";
// export const WORD_INCORRECT_PASSWORD = "كلمة المرور غير صحيحة";
// export const WORD_ENTER_PASSWORD = "أدخل كلمة المرور";
// export const WORD_DASHBOARD = "لوحة التحكم";
// export const WORD_MANAGE_REQUEST = "إدارة الطلبات";
// export const WORD_MANAGE_USER_ROLE = "إدارة مهام المستخدم";
// export const WORD_PENDING_REQUEST = "الطلبات قيد الإنتظار";
// export const WORD_LOGOUT = "تسجيل الخروج";
// export const WORD_REPORTS = "التقارير";
// export const WORD_SEARCH = "بحث";
// export const WORD_SHOW = "إظهار";
// export const WORD_ENTRIES = "الإدخالات";
// export const WORD_REQUEST_ID = "الرقم التعريفي للطلب";
// export const WORD_TITLE = "العنوان";
// export const WORD_CURRENT_STATUS = "الحالة";
// export const WORD_CREATED_DATE = "تاريخ الإنشاء";
// export const WORD_ACTION = "نشاط";
// export const WORD_REQUEST_DETAILS = "تفاصيل الطلب";
// export const WORD_DETAILS = "التفاصيل";
// export const WORD_STATUS = "الحالة";
// export const WORD_REQUESTER = "مقدم الطلب";
// export const WORD_DESCRIPTION = "الوصف";
// export const WORD_REQUEST_FILE = "ملف الطلب";
// export const WORD_REQUEST_FILE_LABEL = "للتوقيع*";
// export const WORD_DOCUMENT = "مستند";
// export const WORD_NAME = "الاسم";
// export const WORD_SUBUNIT = "الشعبة";
// export const WORD_APPROVAL_TIMES = "وقت الموافقة";
// export const WORD_COMMENTS = "تعليقات";
// export const WORD_APPROVED = "الطلبات المعتمدة";
// export const WORD_APPROVED_REQUEST = "معتمد";
// export const WORD_UNIT = "الوحدة التنظيمية";
// export const WORD_CREATE_REQUEST = "إنشاء طلب";
// export const WORD_SUPPORTING_DOCUMENTS = 'المرفقات الداعمة';
// export const WORD_CLOSED_REQUEST = "الطلبات المغلقة";
// export const WORD_CREATE_NEW_REQUEST = "إنشاء طلب جديدة";
// export const WORD_ENTER_REQUEST_DETAILS = "إدخال تفاصيل الطلب";
// export const WORD_FIELD_CANT_BLANK = "الملف لا يمكن أن يكون فارغ";
// export const WORD_ENTER_TITLE = "أدخل العنوان";
// export const WORD_ENTER_DESCRIPTION = "أدخل الوصف";
// export const WORD_ADD_ATTACHMENT = "أضف المرفق للتوقيع";
// export const WORD_NEXT = "التالي";
// export const WORD_CANCEL = "إلغاء";
// export const WORD_PENDING_REQUEST_DETAILS = "تفاصيل الطلب قيد الإنتظار";
// export const WORD_MANAGE_COMMENTS = "التعليقات";
// export const WORD_RETURNED = "الطلبات المرتجعة";
// export const WORD_RETURNED_REQUEST = "مرتجع";
// export const WORD_SECRETARY_NOTE = "ملاحظات السكرتير";
// export const WORD_SUPER_ADMIN = "المسؤول الأعلى";
// export const WORD_NOTES = "الملاحظات";
// export const WORD_COMMENT_HERE = "اكتب تعليقك هنا";
// export const WORD_ASSIGN_SIGNATORY = "تعيين المعتمد";
// export const WORD_MANAGE_REQUEST_DETAILS = "تفاصيل الطلب ";
// export const WORD_SET_NO_SIGNATORIES = "تحديد عدد الموقعين";
// export const WORD_SIGNATORY1 = "الموقع الأول";
// export const WORD_NO_OPTIONS = "لا يوجد خيارات";
// export const WORD_SELECT_UNIT = "اختر الوحدة التنظيمية";
// export const WORD_SELECT_SUBUNIT = "اختر الشعبة";
// export const WORD_PROCUREMENT = "شراء";
// export const WORD_HR = "الموارد البشرية";
// export const WORD_FINANCE = "المالية";
// export const WORD_SELECT_USER = "اختر المستخدم";
// export const WORD_ACTION_WO_COMMENT = "نشاط بدون تعليق";
// export const WORD_SECRETARY = "سكرتير";
// export const WORD_ALL_SIGNATORIES_NOT_SELECTED = "لم يتم اختيار جميع الموقعين";
// export const WORD_PENDING = "الطلبات الجديدة";
// export const WORD_PENDING_REQUEST_DETAIL = "قيد الاعتماد ";
// export const WORD_ADD_SIGNATURE = "إضافة توقيع";
// export const WORD_ASSIGN_ROLE = "تفويض مهام";
// export const WORD_SELECT = "اختيار";
// export const WORD_USERS = "المستخدمين";
// export const WORD_SIGNATURE = "التوقيع";
// export const WORD_ACTIONS = "النشاطات";
// export const WORD_UPLOAD = "رفع";
// export const WORD_UPLOAD_SIGNATURE = "رفع التوقيع";
// export const WORD_SECRETARY_PERMISSION = "صلاحية السكرتير";
// export const WORD_PERMISSION_CHANGED_SUCCESSFULLY = "تم تغيير الصلاحية بنجاح";
// export const WORD_CONFIRM = "تأكيد";
// export const WORD_FROM = "من";
// export const WORD_TO = "إلى ";
// export const WORD_USER_TYPE = "نوع المستخدم";
// export const WORD_USER = "نوع المستخدم";
// export const WORD_ENTER_KEYWORD = "أدخل كلمة البحث";
// export const WORD_START_DATE = "تاريخ البدء";
// export const WORD_END_DATE = "تاريخ الإنتهاء";
// export const WORD_SELECT_STATUS = "اختر الحالة";
// export const WORD_REPORT_APPROVED = "موافق";
// export const WORD_REJECTED = "الطلبات المرفوضة";
// export const WORD_REJECTED_REQUEST = "مرفوض";
// export const WORD_ALL = "الجميع";
// export const WORD_SIGNATORY = "المعتمد";
// export const WORD_PLEASE_ENTER_SEARCH = "يرجى إدخال كلمة البحث";
// export const WORD_EXPORT = "إصدار / تصدير";
// export const WORD_REPORT_REQUEST_DETAILS = "تقرير الطلب";
// export const WORD_REPORT_DOCUMENT = "المستند";
// export const WORD_INPROGRESS_REQUEST = "طلبات قيد الاجراء";
// export const WORD_CLEAR = "مسح";
// export const WORD_BACK = "تراجع";
// export const WORD_JOB_TITLE = "المسمى الوظيفي";
// export const WORD_RANK = "الرتبة";
// export const WORD_VIEW_COMMENTS = "اظهار التعليقات";
// export const WORD_APPROVE_WO_COMMENT = "الاعتماد بدون تعليق";
// export const WORD_SHOW_SIGNATURE = 'إظهار التوقيع'
// export const WORD_SIGNATORY_NAME_NOT_SELECTED = "لم يتم اختيار اسم المعتمد";
// export const WORD_SIGNATORIES_ASSIGNED_SUCCESFULLY = "تم تعيين المعتمدين بنجاح";
// export const WORD_SELECT_RANK = "تحديد الرتبة";
// export const WORD_TYPE_JOB_TITLE = "اكتب المسمى الوظيفي";
// export const WORD_SELECT_SIGNATORY = "تحديد المعتمد";
// export const WORD_OF = "من";
// export const WORD_SIGNATURE_BOX_NOT_SPECIFIED = "لم يتم تحديد خانات التوقيع لجميع المعتمدين";
// export const WORD_SUBMIT = "تقديم";
// export const WORD_NOT_PDF_FILE = "(PDF) ليس ملف";
// export const WORD_DELETE = "حذف";
// export const WORD_COMMENT_REQUIRED = "التعليق مطلوب";
// export const WORD_REQUEST_REJECTED = "تم رفض الطلب";
// export const WORD_REQUEST_RETURNED = "تم إرجاع الطلب";
// export const WORD_REQUEST_APPROVED = "تم اعتماد الطلب";
// export const WORD_TIME = "الوقت";
// export const WORD_REJECT = "رفض";
// export const WORD_RETURN = "إرجاع";
// export const WORD_APPROVE = "اعتماد";
// export const WORD_ONLY_PDF_CAN_UPLOAD = "فقط (PDF) يجب تحميل ملف";
// export const WORD_SET_NUMBER_OF = "تحديد عدد المعتمدين";
// export const WORD_PLEASE_ENTER_SEARCH_DATA = "الرجاء إدخال بيانات البحث";
// export const WORD_PLEASE_SELECT_ROLE = "الرجاء تحديد المهام";
// export const WORD_PLEASE_SELECT_USER = "الرجاء تحديد المستخدم";
// export const WORD_ROLES_ADDED_SUCCESFULLY = "تم إضافة مهام المستخدم بنجاح";
// export const WORD_ADD_ROLE = "إضافة مهام";
// export const WORD_KEYWORD = "كلمة البحث";
// export const WORD_SELECT_ALL = "تحديد الكل";
// export const WORD_EMAIL = "البريد الالكتروني";
// export const WORD_NO_DATA_FOUND = "لا توجد بيانات";
// export const WORD_CREATE = "إنشاء";
// export const WORD_ONLY_UPLOAD_PNG = "فقط (PNG) يجب تحميل ملف";
// export const WORD_SL_NO = "م";
// export const WORD_ROLE_DELETED_SUCCESFULLY = "تم سحب المهام بنجاح";
// export const WORD_DO_YOU_WANT_TO_DELETE = "هل تريد أن تحذف؟";
// export const WORD_SENT_TO = "تم الارسال إلى";
// export const WORD_APPROVED_BY = "تم الاعتماد من قبل";
// export const WORD_RETURNED_TO = "تم الإرجاع إلى";
// export const WORD_REJECTED_BY = "تم الرفض من قبل";
// export const WORD_REQUESTS = "الطلبات";
// export const WORD_SEARCH_NAME_ID_USERNAME_JOBTITLE = "البحث عن اسم أو الرقم الوظيفي أو اسم المستخدم أو المسمى الوظيفي";
// export const WORD_SELECT_USER_ROLE = "حدد مهام المستخدم";
// export const WORD_MODIFY = "تعديل";
// export const WORD_USER_ROLES_UPDATED_SUCCESFULLY = "تم تحديث مهام المستخدم بنجاح";
// export const WORD_EDIT_ROLE = "تغيير المهام";
// export const WORD_EDIT_USER_ROLE = "تغيير مهام المستخدم ";
// export const WORD_SIGNATORIES = "المعتمدون";
// export const WORD_AVAILABLE = "متاح";
// export const WORD_NOT_AVAILABLE = "غير متاح";
// export const WORD_SIGNATURE_STATUS = "حالة الاعتماد";
// export const WORD_LEAVE = "المغادرة";
// export const WORD_STAY = "البقاء";
// export const WORD_UPDATE_SIGNATURE = "تحديث التوقيع";
// export const WORD_DELETE_SIGNATURE = "حذف التوقيع";
// export const WORD_ROLES_ADDED_BY = "تم إضافة المهام من قبل";
// export const WORD_CREATED_BY = "تم الإنشاء من قبل";
// export const WORD_ALL_USERS = "جميع المستخدمين";
// export const WORD_INPROGRESS = "طلباتي";
// export const WORD_MY_REQUESTS = "طلباتي";
// export const WORD_WORD_LIMIT_EXCEEDED = "لقد تجاوزت الحد الأقصى البالغ 100 حرف";
// export const WORD_ENTER_USERNAME = "ادخال اسم المستخدم";
// export const WORD_ENTER_LOGIN_PASSWORD = "ادخال كلمة السر";
// export const WORD_FIELD_CANT_BLANK_WARNING = "لا يمكن أن يكون الحقل فارغ";
// export const WORD_SHOULD_ATTACH_PDF_FILE = "(PDF) يجب ارفاق ملف";
// export const WORD_SYNC_AD = "إعلان متزامن";
// export const WORD_LEAVE_CONFIRMATION = "هل أنت متأكد من المغادرة؟";
// export const WORD_FILE_FORMAT_NOT_SUPPORTED = "نوع الملف غير صحيح";
// export const WORD_FILE_UPLOAD_SUPPORTED_FORMATS = "الملفات المعتمدة : pdf, docx, xlsx, jpeg, png, jpg, csv, ppt, pptx";
// export const WORD_ADD_SUPPORTING_DOCS = "اضف المرفقات الداعمة";
// export const WORD_DELETE_CONFIRMATION = "هل أنت متأكد أنك تريد حذف";
// export const WORD_YES = "نعم";
// export const WORD_NO = "لا";
// export const WORD_DELETED_SUCCESSFULLY = "تم الحذف بنجاح"
// export const WORD_REVIEWER = 'مراجع'
// export const WORD_ARCHIVED_REQUEST = "الطلبات المؤرشفة";
// export const WORD_ARCHIVED_DETAILS = "بيانات الطلبات المؤرشفة";
// export const WORD_DELETED_BY = "تم الحذف من قبل";
// export const WORD_DELETED_DATE = "تاريخ الحذف";
// export const WORD_STATUS_DELETE = "حالةالطلب قبل الحذف";
// export const WORD_CLOSED_REQUEST_DETAILS = 'تفاصيل الطلب المغلقة'
// export const WORD_INPROGRESS_REQUEST_DETAILS = 'تفاصيل الطلب قيد الاجراء'
// export const WORD_REPORT = 'تقرير'
// export const WORD_EMPLOYEE_ID = "الرقم الوظيفي";
// export const WORD_RESPONSIBLE_USER = "المعني بالاجراء";
// export const WORD_SEARCH_KEYWORD = "البحث عن عنوان الطلب أو اسم مستخدم أو الرقم الوظيفي";
// export const WORD_TECH_SUPPORT = "a_Tech Support";
// export const WORD_TECHSUPPORT_PENDING_REQUEST_DETAILS = "a_Techsupport pending request details";
// export const WORD_CLOSE = "a_Close";
// export const WORD_FILE_TOO_BIG_VALIDATION = "حجم الملف كبير، يرجى اختيار ملف أقل من 50 MB"
// export const WORD_E_SIGN_REPORT = "a_E-sign Report"
// export const WORD_TECH_SUPPORT_REPORT = "a_Tech support"
// export const WORD_CLOSED = "a_Closed"
// export const WORD_SEARCH_KEYWORD_TEXT = "a_Search for request ID or title"
// export const WORD_E_SIGN_REQUESTS = "a_E-Sign Requests";
// export const WORD_TECH_SUPPORT_PENDING_REQUEST = "a_Tech Support Pending Request";
// export const WORD_TECH_SUPPORT_CLOSED_REQUEST = "a_Tech Support Closed Request";
// export const WORD_ACTION_DETAILS = "a_Tech Support Action Details";
// export const WORD_NEW_REQUEST = "a_New Request"
// export const WORD_ACTIVE_DIRECTORY_SYNC_SUCCESSFULLY = "تم تفعيل عملية نقل الدليل النشط "
// export const WORD_REQUEST_ADDED_SUCCESSFULLY = "تم إضافة الطلب بنجاح"
// export const WORD_REQUEST_UPDATED_SUCCESSFULLY = "تم تحديث الطلب بنجاح"
// export const WORD_REQUEST_CLOSED_SUCCESSFULLY = "تم إغلاق الطلب بنجاح"
// export const WORD_SIGNATURE_DELETED_SUCCESSFULLY = "تم حذف التوقيع بنجاح"
// export const WORD_SIGNATURE_ADDED_SUCCESSFULLY = "تم إضافة التوقيع بنجاح"

export const WORD_SHOW_SIGNATURE = 'Show Signature'
export const WORD_SIGN_INTO = "Sign into your Dashboard";
export const WORD_USERNAME = "Username";
export const WORD_PASSWORD = "Password";
export const WORD_SIGN_IN = "Sign in";
export const WORD_USERNAME_NOT = "username is not correct";
export const WORD_LOGGED_SUCCESS = "logged in successfully";
export const WORD_ENTER_UNAME = "Enter Username";
export const WORD_INCORRECT_PASSWORD = "Password is not correct";
export const WORD_ENTER_PASSWORD = "Enter Password";
export const WORD_DASHBOARD = "Dashboard";
export const WORD_MANAGE_REQUEST = "Manage requests";
export const WORD_MANAGE_USER_ROLE = "Manage user roles";
export const WORD_PENDING_REQUEST = "Pending Request";
export const WORD_LOGOUT = "Logout";
export const WORD_REPORTS = "Reports";
export const WORD_SEARCH = "search";
export const WORD_SHOW = "show";
export const WORD_ENTRIES = "entries";
export const WORD_REQUEST_ID = "request ID";
export const WORD_TITLE = "Title";
export const WORD_CURRENT_STATUS = "current status";
export const WORD_CREATED_DATE = "created date";
export const WORD_ACTION = "action";
export const WORD_REQUEST_DETAILS = "request details";
export const WORD_DETAILS = "details";
export const WORD_STATUS = "status";
export const WORD_REQUESTER = "requester";
export const WORD_DESCRIPTION = "description";
export const WORD_REQUEST_FILE = "request file";
export const WORD_DOCUMENT = "document";
export const WORD_NAME = "name";
export const WORD_SUBUNIT = "subunit";
export const WORD_APPROVAL_TIMES = "aprroval times";
export const WORD_COMMENTS = "comments";
export const WORD_APPROVED = "Approved";
export const WORD_UNIT = "unit";
export const WORD_CREATE_REQUEST = "Create request";
export const WORD_RETURNED_REQUEST = 'Returned request'
export const WORD_SUPPORTING_DOCUMENTS = 'Supporting Documents';
export const WORD_CLOSED_REQUEST = "closed requests";
export const WORD_CREATE_NEW_REQUEST = "CREATE NEW REQUEST";
export const WORD_ENTER_REQUEST_DETAILS = "Enter Request Details";
export const WORD_FIELD_CANT_BLANK = "The field can't be blank";
export const WORD_ENTER_TITLE = "enter title";
export const WORD_ENTER_DESCRIPTION = "enter description";
export const WORD_ADD_ATTACHMENT = "add attachment";
export const WORD_NEXT = "next";
export const WORD_CANCEL = "cancel";
export const WORD_PENDING_REQUEST_DETAILS = "Pending request details";
export const WORD_PENDING_REQUEST_DETAIL = "Pending request detail";
export const WORD_MANAGE_COMMENTS = "comments";
export const WORD_SECRETARY_NOTE = "Secretary Note";
export const WORD_SUPER_ADMIN = "Super Admin";
export const WORD_NOTES = "Notes";
export const WORD_COMMENT_HERE = "Comment here";
export const WORD_ASSIGN_SIGNATORY = "Assign Signatory";
export const WORD_MANAGE_REQUEST_DETAILS = "Request details";
export const WORD_SET_NO_SIGNATORIES = "Set number of signatories";
export const WORD_SIGNATORY1 = "Signatory 1";
export const WORD_NO_OPTIONS = "no options";
export const WORD_SELECT_UNIT = "select unit";
export const WORD_SELECT_SUBUNIT = "select subunit";
export const WORD_PROCUREMENT = "procurement";
export const WORD_HR = "HR";
export const WORD_FINANCE = "Finance";
export const WORD_SELECT_USER = "select user";
export const WORD_ACTION_WO_COMMENT = "Action without comment";
export const WORD_SECRETARY = "Secretary";
export const WORD_ALL_SIGNATORIES_NOT_SELECTED = "all signatories are not selected";
export const WORD_PENDING = "Pending";
export const WORD_ADD_SIGNATURE = "add signature";
export const WORD_ASSIGN_ROLE = "assign role";
export const WORD_SELECT = "select";
export const WORD_USERS = "users";
export const WORD_SIGNATURE = "signature";
export const WORD_ACTIONS = "actions";
export const WORD_UPLOAD = "upload";
export const WORD_UPLOAD_SIGNATURE = "upload signature";
export const WORD_SECRETARY_PERMISSION = "secretary permission";
export const WORD_PERMISSION_CHANGED_SUCCESSFULLY = "permission changed successfully";
export const WORD_CONFIRM = "confirm";
export const WORD_FROM = "from";
export const WORD_TO = "to";
export const WORD_USER_TYPE = "user type";
export const WORD_USER = "user";
export const WORD_ENTER_KEYWORD = "enter keyword";
export const WORD_START_DATE = "start date";
export const WORD_END_DATE = "end date";
export const WORD_SELECT_STATUS = "select status";
export const WORD_REPORT_APPROVED = "aprroved";
export const WORD_REJECTED = "rejected";
export const WORD_RETURNED = "returned";
export const WORD_ALL = "all";
export const WORD_SIGNATORY = "signatory";
export const WORD_PLEASE_ENTER_SEARCH = "please enter search value";
export const WORD_EXPORT = "export";
export const WORD_REPORT_REQUEST_DETAILS = "report request details";
export const WORD_REPORT_DOCUMENT = "document";
export const WORD_REJECTED_REQUEST = "Rejected request";
export const WORD_APPROVED_REQUEST = "Approved request";
export const WORD_INPROGRESS_REQUEST = "InProgress Requests";
export const WORD_CLEAR = "Clear";
export const WORD_BACK = "Back";
export const WORD_JOB_TITLE = "Job Title";
export const WORD_RANK = "Rank";
export const WORD_VIEW_COMMENTS = "View comments";
export const WORD_APPROVE_WO_COMMENT = "Approve without comment";
export const WORD_SIGNATORY_NAME_NOT_SELECTED = "Signatory name is not selected";
export const WORD_SIGNATORIES_ASSIGNED_SUCCESFULLY = "Signatories assigned successfully";
export const WORD_SELECT_RANK = "Select rank";
export const WORD_TYPE_JOB_TITLE = "Type job title";
export const WORD_SELECT_SIGNATORY = "Select Signatory";
export const WORD_OF = "of";
export const WORD_SIGNATURE_BOX_NOT_SPECIFIED = "Signature Boxes are not specified for all signatories";
export const WORD_SUBMIT = "Submit";
export const WORD_NOT_PDF_FILE = "Not a PDF file";
export const WORD_DELETE = "Delete";
export const WORD_COMMENT_REQUIRED = "Comment is required";
export const WORD_REQUEST_REJECTED = "Request is rejected";
export const WORD_REQUEST_RETURNED = "Request is returned";
export const WORD_REQUEST_APPROVED = "Request is approved";
export const WORD_TIME = "Time";
export const WORD_REJECT = "Reject";
export const WORD_RETURN = "Return";
export const WORD_APPROVE = "Approve";
export const WORD_ONLY_PDF_CAN_UPLOAD = "Only PDF file can be uploaded";
export const WORD_SET_NUMBER_OF = "Set number of Signatories";
export const WORD_PLEASE_ENTER_SEARCH_DATA = "Please enter search data";
export const WORD_PLEASE_SELECT_ROLE = "Please select role";
export const WORD_PLEASE_SELECT_USER = "Please select user";
export const WORD_ROLES_ADDED_SUCCESFULLY = "User Roles Added Sucessfully";
export const WORD_ADD_ROLE = "ADD ROLE";
export const WORD_KEYWORD = "Keyword";
export const WORD_SELECT_ALL = "Select All";
export const WORD_EMAIL = "Email";
export const WORD_NO_DATA_FOUND = "No data found";
export const WORD_CREATE = "Create";
export const WORD_ONLY_UPLOAD_PNG = "Upload PNG files only";
export const WORD_SL_NO = "Sl.No";
export const WORD_ROLE_DELETED_SUCCESFULLY = "Role deleted successfully";
export const WORD_DO_YOU_WANT_TO_DELETE = "Do you want to delete?";
export const WORD_SENT_TO = "Sent to";
export const WORD_APPROVED_BY = "Approved by";
export const WORD_RETURNED_TO = "Returned to";
export const WORD_REJECTED_BY = "Rejected by";
export const WORD_REQUESTS = "Requests";
export const WORD_SEARCH_NAME_ID_USERNAME_JOBTITLE = "Search name, employee id, username or job title";
export const WORD_SELECT_USER_ROLE = "Select user role";
export const WORD_MODIFY = "Modify";
export const WORD_USER_ROLES_UPDATED_SUCCESFULLY = "User Roles Updated Sucessfully";
export const WORD_EDIT_ROLE = "Edit Role";
export const WORD_EDIT_USER_ROLE = "Edit user role";
export const WORD_SIGNATORIES = "Signatories";
export const WORD_AVAILABLE = "Available";
export const WORD_NOT_AVAILABLE = "Not Available";
export const WORD_SIGNATURE_STATUS = "Signature Status";
export const WORD_LEAVE = "Leave";
export const WORD_STAY = "Stay";
export const WORD_UPDATE_SIGNATURE = "Update Signature";
export const WORD_DELETE_SIGNATURE = "Delete Signature";
export const WORD_ROLES_ADDED_BY = "Roles Added By";
export const WORD_CREATED_BY = "Created By";
export const WORD_ALL_USERS = "All Users";
export const WORD_INPROGRESS = "In progress";
export const WORD_MY_REQUESTS = "My Requests";
export const WORD_WORD_LIMIT_EXCEEDED = "You have exceeded the maximum limit of 100 characters";
export const WORD_ENTER_USERNAME = "Enter username";
export const WORD_ENTER_LOGIN_PASSWORD = "Enter password";
export const WORD_FIELD_CANT_BLANK_WARNING = "The field can't be blank";
export const WORD_SHOULD_ATTACH_PDF_FILE = "Should attach a pdf file";
export const WORD_SYNC_AD = "Sync AD";
export const WORD_DELETE_CONFIRMATION = "Are you sure you want to delete";
export const WORD_LEAVE_CONFIRMATION = "Are you sure to leave?";
export const WORD_FILE_FORMAT_NOT_SUPPORTED = "File format not supported";
export const WORD_FILE_UPLOAD_SUPPORTED_FORMATS = "Supported files: pdf, docx, xlsx, jpeg, png, jpg, csv, ppt, pptx";
export const WORD_ADD_SUPPORTING_DOCS = "Add supporting documents";
export const WORD_YES = "Yes";
export const WORD_NO = "No";
export const WORD_REQUEST_FILE_LABEL = "To sign*"
export const WORD_DELETED_SUCCESSFULLY = "Deleted successfully"
export const WORD_REVIEWER = 'Reviewer'
export const WORD_ARCHIVED_REQUEST = "Archived request";
export const WORD_ARCHIVED_DETAILS = "Archived details";
export const WORD_DELETED_BY = "Deleted by";
export const WORD_DELETED_DATE = "Deleted date";
export const WORD_STATUS_DELETE = "Status before delete";
export const WORD_CLOSED_REQUEST_DETAILS = 'Closed request details'
export const WORD_INPROGRESS_REQUEST_DETAILS = 'Inprogress request details'
export const WORD_REPORT = 'Report'
export const WORD_EMPLOYEE_ID = "Employee ID";
export const WORD_RESPONSIBLE_USER = "Responsible user";
export const WORD_SEARCH_KEYWORD = "Search for employee, request ID or title";
export const WORD_TECH_SUPPORT = "Tech Support";
export const WORD_TECHSUPPORT_PENDING_REQUEST_DETAILS = "Techsupport pending request details";
export const WORD_CLOSE = "Close";
export const WORD_FILE_TOO_BIG_VALIDATION = "File too Big, please select files less than 50mb"
export const WORD_E_SIGN_REPORT = "E-sign Report"
export const WORD_TECH_SUPPORT_REPORT = "Tech support"
export const WORD_CLOSED = "Closed"
export const WORD_SEARCH_KEYWORD_TEXT = "Search for request ID or title"
export const WORD_E_SIGN_REQUESTS = "E-Sign Requests";
export const WORD_TECH_SUPPORT_PENDING_REQUEST = "Tech Support Pending Request";
export const WORD_TECH_SUPPORT_CLOSED_REQUEST = "Tech Support Closed Request";
export const WORD_ACTION_DETAILS = "Tech Support Action Details";
export const WORD_NEW_REQUEST = "New Request"
export const WORD_ACTIVE_DIRECTORY_SYNC_SUCCESSFULLY = "Active Directory Sync Succesfully"
export const WORD_REQUEST_ADDED_SUCCESSFULLY = "Request Added Successfully"
export const WORD_REQUEST_UPDATED_SUCCESSFULLY = "Request updated successfully"
export const WORD_REQUEST_CLOSED_SUCCESSFULLY = "Request closed successfully"
export const WORD_SIGNATURE_DELETED_SUCCESSFULLY = "Signature deleted successfully"
export const WORD_SIGNATURE_ADDED_SUCCESSFULLY = "Signature added successfully"
