import React from "react";
import { HashRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-toastify/dist/ReactToastify.css";
import './style.scss'
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
import { employee_id, permissionModules, username } from "./redux/commonReducer";
import ScrollToTop from "./ScrollTop";
import ReactWaterMark from "react-watermark-component";
import { useEffect } from "react";
import { useState } from "react";
import RouteHandler from "./route";

const options = {
  chunkWidth: 200,
  chunkHeight: 60,
  textAlign: "left",
  textBaseline: "bottom",
  globalAlpha: 0.17,
  rotateAngle: -0.26,
  fillStyle: "#666",
};

export default function App() {
  
  const permission= useSelector(permissionModules);

  const [text, setText] = useState("");
  const emp_id = useSelector(employee_id);

  useEffect(() => {
    if (localStorage.getItem("employee_id")) {
      setText(localStorage.getItem("employee_id"));
    } else {
      setText("");
    }
  }, [localStorage.getItem("employee_id"), emp_id]);

  return (
    <div className="App">
      <HashRouter>
        <ToastContainer />
        <ScrollToTop/>
        {text != "" ? (
            <ReactWaterMark
              waterMarkText={text ? text : ""}
              openSecurityDefense = {true}
              // securityAlarm={beginAlarm}
              options={options}
            >
              <RouteHandler />
            </ReactWaterMark>
          ) : (
            <RouteHandler />
          )}
      </HashRouter>
    </div>
  );
}