import React, { useState } from "react";
import PageSubTitle from "../../components/PageSubTitle/PageSubTitle";
import RequestDetails from "../../components/RequestDetails/RequestDetails";
import {
  WORD_ARCHIVED,
  WORD_ARCHIVED_DETAILS,
  WORD_BACK,
  WORD_CLOSED,
  WORD_CLOSED_REQUEST_DETAILS,
  WORD_DETAILS,
  WORD_INPROGRESS,
  WORD_INPROGRESS_REQUEST_DETAILS,
  WORD_MANAGE_REQUEST_DETAILS,
  WORD_PENDING,
  WORD_PENDING_REQUEST_DETAILS,
  WORD_REPORT,
} from "../../constants/translation";
import { useNavigate } from "react-router";

export default function RequestDetailsPage({ page = "" }) {

  const navigate = useNavigate()

  return (
    <div id="PendingRequestDetails">
      <h1 className="page-title">
        {
          page === 'pending' ? WORD_PENDING_REQUEST_DETAILS
            : page === 'closed' ? WORD_CLOSED_REQUEST_DETAILS
              : page === 'in-Progress' ? WORD_INPROGRESS_REQUEST_DETAILS
                : page === 'Report-' ? WORD_REPORT
                  : page === 'archived' ? WORD_ARCHIVED_DETAILS
                    : null
        }
      </h1>
      <div className="backButtonSection">
        <button className="back" onClick={() => window.history.back()}>{WORD_BACK}</button>
      </div>
      <section className="title">
        {/* <button className="back" onClick={() => navigate('../')}>Back</button> */}
        <PageSubTitle name={WORD_DETAILS} />
      </section>
      <section>
        <RequestDetails page={page} />
      </section>
    </div>
  );
}
