import React from "react";
import "./SetPersonCount.scss";
import Select from "react-select";
import { WORD_SET_NUMBER_OF,WORD_SIGNATORIES } from "../../constants/translation";

export default function SetPersonCount({ name, state, setState, handleData, inputs }) {
  const values = [
    { id: 1, value: 1 },
    { id: 2, value: 2 },
    { id: 3, value: 3 },
    { id: 4, value: 4 },
    { id: 5, value: 5 },
    { id: 6, value: 6 },
    { id: 7, value: 7 },
    { id: 8, value: 8 },
    { id: 9, value: 9 },
    { id: 10, value: 10 },
  ];
  // console.log({state})
  return (
    <div id="SetPersonCount">
     <div className="col-md-3">
     <label htmlFor="">{WORD_SET_NUMBER_OF} </label>
     <Select 
       name="unit"
       options={values}
       // isDisabled={fullUserList?.length == 0}
       hideSelectedOptions={false}
       value={state}
       getOptionLabel={(option) => option.value}
       getOptionValue={(option) => option.id}
       className="basic-select w-100"
       classNamePrefix="select"
       styles={{
         control: (base) => ({
           ...base,
          //  width: 200,
           // minHeight: 35
         }),
       }}
       onChange={(e) => {
        setState(e) 
        if(handleData != undefined) {
          handleData(e, inputs)
        }
      }
      }
     />
     </div>
        {/* <img src="./assets/icons/arrow-down.svg" alt="" /> */}
        <hr className="mt-5" />
    </div>
   
  );
}
