import React from "react";
import ReactPaginate from "react-paginate";

const Pagination = ({ page, pageCount, handlePageChange }) => {
  return (
    <div className="paginationStyle">
      {pageCount > 1 ? (
        <ReactPaginate
          previousLabel={<>❮</>}
          nextLabel={<>❯</>}
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          // breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          pageCount={pageCount}
          marginPagesDisplayed={1}
          pageRangeDisplayed={5}
          // initialPage={page}
          forcePage={page}
          onPageChange={(e) => handlePageChange(e)}
          containerClassName="pagination"
          activeClassName="pageActive"
        />
      ) : null}
    </div>
  );
};

export default Pagination;
