import React, { Fragment, createContext, useEffect, useState } from "react";
import "./AssignSignatory.scss";
import FormAddPerson from "../FormAddPerson/FormAddPerson";
import PageSubTitle from "../PageSubTitle/PageSubTitle";
import ButtonsFooter from "../ButtonsFooter/ButtonsFooter";
import { useLocation, useNavigate } from "react-router-dom";
import SetPersonCount from "../SetPersonCount/SetPersonCount";
import { assignSignatory, getRequestDetail, updateSignatory } from "../../redux/actionCreator";
import { toast } from "react-toastify";
import {
  WORD_ALL_SIGNATORIES_NOT_SELECTED,
  WORD_ASSIGN_SIGNATORY,
  WORD_REQUEST_DETAILS,
  WORD_SIGNATORIES_ASSIGNED_SUCCESFULLY,
  WORD_SIGNATORY_NAME_NOT_SELECTED,
} from "../../constants/translation";
import { useDispatch } from "react-redux";
import { updateRedux } from "../../redux/commonReducer";

export const AssignSignatoryContext = createContext();
export default function AssignSignatory() {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const location = useLocation();
  const [inputs, setInputs] = useState({ Signatory: [], Secretary: [] });
  const [inputErrors, setInputErrors] = useState({});
  const [signatoryCount, setSignatoryCount] = useState({ id: 1, value: 1 });
  const [updater, setUpdater] = useState(true);
  const [prevData, setPrevData] = useState({ Signatory: [], Secretary: [] })
  const [isEditing, setisEditing] = useState(false)

  // console.log({ inputs })




  useEffect(() => {
    let inputdata = inputs
    dispatch(updateRedux({ key: "pdf_open_status", value: true }));
    if (inputdata?.Signatory?.length !== parseInt(signatoryCount.id)) {
      inputdata.Signatory = inputdata?.Signatory?.slice(0, parseInt(signatoryCount.id))
      setInputs(inputdata)
    }
  }, [signatoryCount])

  useEffect(() => {
    if (location.state?.back) {
      setisEditing(true)
      let obj = {
        id: location.state.id,
        type: "0",
        is_archived: 0
      }
      getRequestDetail(obj, (res) => {
        // console.log(res.request_details, 'back button')
        const preData = {
          Signatory: res.request_details.signatory.filter(item => item.level === location.state.level).map((item, i) => ({
            name: item.signatory._id,
            sl_no: i + 1,
            view_comment: item.view_comment,
            comment_allowed: item.comment_allowed,
            isReviewer: item.is_reviewer
            // show_signature: item.show_signature
          })),
          Secretary: res.request_details.secretery.filter(item => item.level === location.state.level).map((item, i) => ({ name: item.secretery._id, sl_no: i + 1 })),
          isReviewer: res.request_details.signatory.some(e => e.is_reviewer && e.status === 'pending')
        }
        // console.log(preData,"preData")
        setInputs(preData)
        setPrevData({
          Signatory: res.request_details.signatory.filter(item => item.level === location.state.level).map((item, i) => ({ username: item.signatory.username, name: item.signatory._id, sl_no: i + 1, name1: item.signatory.name, employee_id: item.signatory.employee_id, rank : item.signatory.rank })),
          Secretary: res.request_details.secretery.filter(item => item.level === location.state.level).map((item, i) => ({ username: item.secretery.username, name: item.secretery._id, sl_no: i + 1, name1: item.secretery.name, employee_id: item.secretery.employee_id,  rank : item.secretery.rank  }))
        })
        // console.log({ preData })
        let signatoriesCount = preData?.Signatory?.length;
        signatoriesCount && setSignatoryCount({ id: signatoriesCount, value: signatoriesCount })
      });
    } else {
      setisEditing(false)
    }
  }, []);

  function onSaveHandle() {
    if (inputs.Signatory.length !== parseInt(signatoryCount.id)) {
      toast.error(WORD_ALL_SIGNATORIES_NOT_SELECTED, {
        position: "bottom-center",
        autoClose: 3000,
      });
      return;
    }
    let flag = true
    inputs.Signatory &&
      inputs.Signatory.forEach((item) => {
        if (item.name === undefined) {
          toast.error(WORD_SIGNATORY_NAME_NOT_SELECTED, {
            position: "bottom-center",
            autoClose: 3000,
          });
          flag = false
        }
      });
    if (!flag) return
    // console.log("location.state.level", location.state.level);
    dispatch(updateRedux({ key: 'loader1', value: true }))
    inputs.Signatory.sort((a, b) => { if (a.sl_no > b.sl_no) return 1; if (a.sl_no < b.sl_no) return -1; else return 0 })
    let formdata = new FormData();
    formdata.append("level", location.state.level);
    formdata.append("comment", location.state.comment || "");
    formdata.append("id", location.state.id);
    formdata.append("secretery", inputs?.Secretary[0]?.name);
    formdata.append(`is_reviewer`, inputs.isReviewer);
    inputs?.Signatory.forEach((item, i) => {
      formdata.append(`comment_allowed[${i}]`, item.comment_allowed || false);
      formdata.append(`view_comment[${i}]`, item.view_comment || false);
      //formdata.append(`show_signature[${i}]`, item.show_signature || false);
      formdata.append(`signatory[${i}]`, item.name);
    });
    if (isEditing) {
      updateSignatory(formdata, (res) => {
        if (res.status) {
          // console.log(inputs.isReviewer, inputs.Signatory.length === 1, 'update signatory')
          dispatch(updateRedux({ key: 'loader1', value: false }))
          toast.success(WORD_SIGNATORIES_ASSIGNED_SUCCESFULLY, { position: "bottom-center", autoClose: 3000, });
          if (inputs.isReviewer && inputs.Signatory.length === 1) {
            navigate('/')
          } else navigate("document/" + res.request_id, { state: { level: location.state.level, isHigherLevel: true } });
          dispatch(updateRedux({ key: "pdf_open_status", value: false }))
        }
      });
    } else {
      assignSignatory(formdata, (res) => {
        if (res.status) {
          // console.log(inputs.isReviewer, inputs.Signatory.length === 1, 'assign signatory')
          dispatch(updateRedux({ key: 'loader1', value: false }))
          toast.success(WORD_SIGNATORIES_ASSIGNED_SUCCESFULLY, { position: "bottom-center", autoClose: 3000, });
          if (inputs.isReviewer && inputs.Signatory.length === 1) {
            navigate('/')
          } else navigate("document/" + res.request_id, { state: { level: location.state.level, isHigherLevel: true } });
          dispatch(updateRedux({ key: "pdf_open_status", value: false }))
        }
      });
    }
  }

  // useEffect(() => {
  //   console.log(signatoryCount.value, 1, prevData.Signatory.length, 'measures')
  //   if (signatoryCount.value > prevData.Signatory.length) {
  //     let signatories = prevData.Signatory
  //     for (let i = 0; i < (signatoryCount.value - prevData.Signatory.length); i++) {
  //       console.log(i, 'numbers')
  //       signatories.push({ sl_no: 0 })
  //     }
  //     console.log(signatories, 'asldkfjlak')
  //     setPrevData(s => {
  //       return { ...s, Signatory: signatories }
  //     })
  //   }
  // }, [signatoryCount])

  // console.log(inputs, 'prevData', 'signatoryCount')


  useEffect(() => {
    setTimeout(() => {
      setUpdater(true)
    }, 5000);
  }, [])

  // const timer = () => {
  //   let timeFlag = false
  //   setTimeout(()=>{
  //     timeFlag = true
  //     console.log(timeFlag)
  //     return timeFlag
  //   }, 1000)
  //   console.log(timeFlag,"return timeFlag")
  //   if(timeFlag){
  //     return timeFlag
  //   }
  // }

  return (
    // console.log(prevData,"prevData"),
    <AssignSignatoryContext.Provider
      value={{ setInputErrors, inputErrors, setInputs, inputs }}
    >
      <div id="AssignSignatory">
        <h1 className="page-title">{WORD_REQUEST_DETAILS}</h1>
        <section className="title">
          <PageSubTitle name={WORD_ASSIGN_SIGNATORY} />
        </section>
        <section>
          <SetPersonCount
            name={"signatories"}
            state={signatoryCount}
            setState={setSignatoryCount}
          />
        </section>
        {
          console.log(prevData.Signatory)
        }
        {Array.from({ length: signatoryCount.id }).map((ele, i) => {
          return (
            <Fragment key={i}>
              <section>
                {/* {(isEditing ? prevData.Signatory.length > 0 : true) && } */}
                <FormAddPerson
                  name={"Signatory"}
                  serialNo={i + 1}
                  requestID={location.state?.id}
                  index={i}
                  previousData={(isEditing && prevData?.Signatory?.length) ? { username: prevData?.Signatory[i]?.username, _id: prevData?.Signatory[i]?.name, name: prevData?.Signatory[i]?.name1, employee_id: prevData?.Signatory[i]?.employee_id,  rank : prevData?.Signatory[i]?.rank  } : ""}
                />
              </section>
              <hr />
            </Fragment>
          );
        })}
        <section>
          {/* {
            console.log((isEditing && updater && prevData?.Secretary?.length) ? { username: prevData?.Secretary[0]?.username, _id: prevData?.Secretary[0]?.name } : "","isEditing && updater && prevData?.Secretary?.length")
          } */}
          <FormAddPerson
            name={"Secretary"}
            serialNo={1}
            requestID={location.state?.id}
            index={0}
            previousData={(isEditing && updater && prevData?.Secretary?.length) ? { username: prevData?.Secretary[0]?.username, _id: prevData?.Secretary[0]?.name, name: prevData?.Secretary[0]?.name1, employee_id: prevData?.Secretary[0]?.employee_id, rank: prevData?.Secretary[0]?.rank } : ""}
          />
        </section>
        <section>
          <ButtonsFooter
            page={"AssignSignatory"}
            onCancel={() => {
              navigate(localStorage.getItem("assign_url"))
              dispatch(updateRedux({ key: "pdf_open_status", value: false }))
            }}
            onBack={() => { navigate(localStorage.getItem("assign_url")); dispatch(updateRedux({ key: "pdf_open_status", value: false })); }}
            onSave={onSaveHandle}
          />
        </section>
      </div>
    </AssignSignatoryContext.Provider>
  );
}
